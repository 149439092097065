import React, { useEffect, useState } from 'react';
import { initialCities } from './cities';

const GeolocationComponent = ({ onCityFound }) => {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const hasSharedLocation = localStorage.getItem('hasSharedLocation');

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });

          // Only reload if location is being shared for the first time
          if (!hasSharedLocation) {
            localStorage.setItem('hasSharedLocation', 'true');
            window.location.reload(true);
          }
        },
        (err) => {
          setError("Error getting location: " + err.message);
          console.error("Error getting location:", err);
        }
      );
    } else {
      setError("Geolocation is not supported by your browser");
      console.error("Geolocation is not supported by your browser");
    }
  }, []);

  useEffect(() => {
    if (location) {
      fetchCity(location.latitude, location.longitude);
    }
  }, [location]);

  const fetchCity = async (latitude, longitude) => {
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`);
      const data = await response.json();
      const cityName = data.address.city || data.address.town || data.address.village || "Unknown";
      
      // Check if the detected city is in our list of initial cities
      const normalizedCityName = cityName.trim();
      const cityExists = initialCities.some(
        city => city.toLowerCase() === normalizedCityName.toLowerCase()
      );

      if (cityExists) {
        // Only call onCityFound if the city is in our list
        if (onCityFound) {
          onCityFound(normalizedCityName);
        }
      } else {
        console.log(`City "${normalizedCityName}" not found in supported cities list`);
        // Don't call onCityFound, effectively ignoring the geolocation
      }
    } catch (err) {
      setError("Error fetching city: " + err.message);
      console.error("Error fetching city:", err);
    }
  };

  return null;
};

export default GeolocationComponent;