import React, { useState, useEffect } from 'react';
import './CommissionCalculator.css';

const CommissionCalculator = () => {
  const [value, setValue] = useState(1000000); // Initial home price
  const [sliderPosition, setSliderPosition] = useState(0); // To handle the slider label position

  const formatNumber = (number) => {
    return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const updateValues = (value) => {
    const modernCommission = value * 0.01;
    const outdatedCommission = value * 0.025;
    const savings = outdatedCommission - modernCommission;

    document.getElementById('other-realtors-price').textContent = formatNumber(outdatedCommission);
    document.getElementById('modern-solution-realty-price').textContent = formatNumber(modernCommission);
    document.getElementById('you-save-price').textContent = formatNumber(savings);
  };

  const calculateSliderPosition = (value) => {
    const slider = document.getElementById('priceSlider');
    const max = slider.max;
    const min = slider.min;
    const percent = ((value - min) / (max - min)) * 100;
    return percent; // Calculate percentage position of slider handle
  };

  useEffect(() => {
    updateValues(value);
    setSliderPosition(calculateSliderPosition(value)); // Set initial slider position
  }, [value]);

  // Handle slider position updates
  const handleSliderChange = (e) => {
    const newValue = parseInt(e.target.value);
    setValue(newValue);
    setSliderPosition(calculateSliderPosition(newValue)); // Update slider position dynamically
  };

  return (
    <div className="commission-calculator-container">
      <div className="slider-container">
        <div 
          className="slider-label-container"
          style={{ left: `calc(${sliderPosition}% - 40px)` }} // Adjust the position dynamically
        >
          <div id="house-value-price" className="slider-value">
            {formatNumber(value)}
          </div>
          {/* Place the Home Price text here so it moves with the slider */}
          <div className="slider-label">Home Price</div>
        </div>
        <input 
          type="range" 
          min="500000" 
          max="5000000" 
          value={value} 
          className="slider" 
          id="priceSlider" 
          onChange={handleSliderChange}
        />
      </div>
  
      <div className="calculator-values">
        <div className="traditional-brokerage-column value-column">
          <div className="value-title">Traditional Brokerage</div>
          <h5>2.5% Commission</h5>
          <div id="other-realtors-price" className="value">{formatNumber(value * 0.025)}</div>
        </div>
        <div className="msr-column value-column">
          <div className="value-title">Modern Solution Realty</div>
          <h5 className="commission-text">1% Commission<br></br></h5>
          <div id="modern-solution-realty-price" className="value black-text">{formatNumber(value * 0.01)}</div>
        </div>
      </div>
  
      <div className="save-column value-column">
        <div className="value-title">With Modern Solution Realty, you save</div>
        <div id="you-save-price" className="value" style={{ color: '#67ad5e' }}>{formatNumber(value * 0.015)}</div>
      </div>
    </div>
  );
};

export default CommissionCalculator;
