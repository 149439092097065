import React, { useState, useEffect, useRef } from 'react';
import axios from '../services/axios';
import './ListingWidget.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faBed, faBath } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as BedroomIcon } from '../assets/icons/bedroom-3.svg';
import { ReactComponent as BathroomIcon } from '../assets/icons/bathroom.svg';
import { ReactComponent as SquareFeetIcon } from '../assets/icons/squarefeet.svg';

const ListingWidget = () => {
  const [listings, setListings] = useState([]);
  const sliderRef = useRef(null);
  const isDraggingRef = useRef(false);
  const startXRef = useRef(0);
  const scrollLeftRef = useRef(0);

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const response = await axios.post('/listings/bulk');
        setListings(response.data);
      } catch (error) {
        console.error('Error fetching listings:', error);
      }
    };

    fetchListings();
  }, []);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -300,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 300,
        behavior: 'smooth',
      });
    }
  };

  const formatPrice = (price, propertyType) => {
    const formattedPrice = `$${Number(price).toLocaleString()}`;
    return propertyType.toLowerCase() === 'rental' ? `${formattedPrice}/month` : formattedPrice;
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  };

  if (!listings.length) {
    return <div>Loading...</div>;
  }

  return (
    <div className='widget-container'>
      <div className='custom-listing-container'>
        <button onClick={scrollLeft} className='slider-button left'>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        <div className='slider-wrapper'>
          <div
            className='slider'
            ref={sliderRef}
          >
            {listings.concat(listings).map((listing, index) => {
              const photoUrls = listing.photo_urls ? listing.photo_urls : [];
              const firstPhotoUrl = photoUrls.length > 0 ? photoUrls[0] : '';
              const isRental = listing.property_type.toLowerCase() === 'rental';
              const address = capitalizeWords(listing.address);
              const hasCommunity = !!listing.community;

              return (
                <Link
                  to={`/listing/${listing.ml_num}/${address.toLowerCase().replace(/\s+/g, '-')}`}
                  key={index}
                  className='custom-listing-column'
                >
                  <div className='image-container'>
                    <div className="cashback-badge">
                      <Link to="/contact">{isRental ? "For Rent" : "Buy and Get $5000 Rebate"}</Link>
                    </div>
                    {firstPhotoUrl ? (
                      <img src={firstPhotoUrl} alt='Listing' />
                    ) : (
                      <p>Image not available</p>
                    )}
                  </div>
                  
                  <div className='listing-info'>
                  <div className="listing-details">
  <div className="listing-detail-item">
    <BedroomIcon className="icon" />
    <span className="with-words">{listing.bedrooms > 0 ? listing.bedrooms : '-'}</span>
    <span className="without-words">{listing.bedrooms > 0 ? listing.bedrooms : '-'}</span>
  </div>
  <div className="listing-detail-item">
    <BathroomIcon className="icon" />
    <span className="with-words">{listing.bathrooms > 0 ? listing.bathrooms : '-'}</span>
    <span className="without-words">{listing.bathrooms > 0 ? listing.bathrooms : '-'}</span>
  </div>
  <div className="listing-detail-item">
    <SquareFeetIcon className="icon" />
    <span className="with-words">{listing.square_feet > 0 ? listing.square_feet : '-'}</span>
    <span className="without-words">{listing.square_feet > 0 ? listing.square_feet : '-'}</span>
  </div>
</div>
                    <div className='listing-title'>
                      <h3 className="street-address">{address}</h3>
                      <p className="community">
                        {hasCommunity && listing.community} 
                        {hasCommunity && <span className="separator-dot">•</span>} 
                        {listing.municipality}
                      </p>
                    </div>
                    
                    
                    
                    <div className='listing-price'>
                      {formatPrice(listing.price, listing.property_type)}
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>

        <button onClick={scrollRight} className='slider-button right'>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};

export default ListingWidget;
