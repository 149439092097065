import React, { useState, useRef, useEffect } from 'react';
import { FaPlay } from 'react-icons/fa';
import GoogleReviews from './GoogleReviews';
import CommissionCalculator from './CommissionCalculator';
import Testimonials from './Testimonials';
import AdvertisingPartners from './AdvertisingPartners';
import Footer from './Footer';
import './Buy.css';
import CityWidget from './CityWidget';
import ListingWidget from './ListingWidget';
import ContactForm from './ContactForm'; // Ensure ContactForm is imported
import thestarLogo from '../assets/images/thestar-logo-2.png';

const Buy = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);

  // Initialize counters for the counter section
  const counters = [
    { icon: require('../assets/images/icon-1.webp'), value: '1%', text: 'Listings for Sellers' },
    { icon: require('../assets/images/icon-2.webp'), value: '$5,000', text: 'Rebate for buyers' },
    { icon: require('../assets/images/icon-3.webp'), value: '2100+', text: 'Properties Sold' },
    { icon: require('../assets/images/icon-4.webp'), value: '$1,100,000+', text: 'Buyers Received in Cashback' },
    { icon: require('../assets/images/icon-5.webp'), value: '$20,150,000+', text: 'Sellers Saved on Commission' },
  ];

  const [showContactForm, setShowContactForm] = useState(false);

  const handleButtonClick = () => {
    setShowContactForm(true);
  };

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsVideoPlaying(true);
    }
  };

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    const elementsToAnimate = document.querySelectorAll('.animate-on-scroll');
    elementsToAnimate.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      elementsToAnimate.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  return (
    <div className="buy-page">
      {/* Video Section */}
      <div className="buy-video-container">
        <div className="video-container">
          {!isVideoPlaying && (
            <div className="unique-play-button" onClick={handlePlayVideo}>
              <FaPlay className="unique-play-icon" />
            </div>
          )}
          <video
            ref={videoRef}
            width="100%"
            height="auto"
            controls
            playsInline={true}
            preload="metadata"
            poster={require('../assets/images/video-poster-buy.webp')}
            onPlay={() => setIsVideoPlaying(true)}
            onPause={() => setIsVideoPlaying(false)}
          >
            <source src={require('../assets/videos/msr-buying-page.mp4')} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      {/* New Full Width Section */}
      <div className="full-width-text-section animate-on-scroll">
        <h2>Get a $5,000 Rebate on Your Dream Home </h2>
        <p>
          At Modern Solution Realty, we're thrilled to offer you a $5,000 rebate when you buy your home with us! Use it for renovations or upgrades to make your new space truly yours. Our expert agents are ready to help you find the perfect home while ensuring you save big. Don't miss out—start your journey with us today!
</p>
<p>You'll receive our full service buying experience with one of our professional agents whose only goal will be to find you the home of your dreams.
        </p>
      </div>

      {/* New Full Width Section */}
      <div className="full-width-text-section animate-on-scroll">
        <h2>How It Works </h2>
        <p>
        We'll be with you every step of the way on your home buying journey, from beginning to end - and our intent will be to give you the highest level of service and create a satisfying experience overall.
</p>

      </div>
      <div className="steps-container">
      {/* Section 1 - Text Left, Image Right */}
      <div className="content-image-section animate-on-scroll">
        <div className="text-content">
          <h2>1. We help you plan out the journey</h2>
          <p>
            Our experienced agents review with you step by step the process of purchasing the right home based on your exact criteria.
          </p>
        </div>
        <div className="image-content">
          <img
            src={require('../assets/images/part1.jpg')}
            alt="How It Works"
            className="image-placeholder"
          />
        </div>
      </div>

      {/* Section 2 - Text Right, Image Left */}
      <div className="content-image-section text-right animate-on-scroll">
        <div className="image-content">
          <img
            src={require('../assets/images/part2.jpg')}
            alt="Why Choose Us?"
            className="image-placeholder"
          />
        </div>
        <div className="text-content">
          <h2>2. We search extensively</h2>
          <p style={{ textAlign: 'right' }}>
            We conduct an extensive search of all MLS® and exclusive listings we have access to. Not all homes for sale are on MLS®. We also search other sources like Zolo.ca, Kijiji.ca, and FSBO websites.
          </p>
        </div>
      </div>

      {/* Section 3 - Text Left, Image Right */}
      <div className="content-image-section animate-on-scroll">
        <div className="text-content">
          <h2>3. We help you plan out the journey</h2>
          <p>
            Our experienced agents review with you step by step the process of purchasing the right home based on your exact criteria.
          </p>
        </div>
        <div className="image-content">
          <img
            src={require('../assets/images/part3.jpg')}
            alt="How It Works"
            className="image-placeholder"
          />
        </div>
      </div>

      {/* Section 4 - Text Right, Image Left */}
      <div className="content-image-section text-right animate-on-scroll">
        <div className="image-content">
          <img
            src={require('../assets/images/part4.jpg')}
            alt="Why Choose Us?"
            className="image-placeholder"
          />
        </div>
        <div className="text-content">
          <h2>4. We consult on each listing with you</h2>
          <p>
            Our experienced agents will help you evaluate the pros and cons of each buying opportunity, arming you with all the information to make informed decisions.
          </p>
        </div>
      </div>

      {/* Section 5 - Text Left, Image Right */}
      <div className="content-image-section animate-on-scroll">
        <div className="text-content">
          <h2>5. We negotiate to get the best price</h2>
          <p>
            The art of negotiation is one of the most crucial realtors’ skills, and our agents are highly trained in this area. You can be assured that they will negotiate tirelessly on your behalf, to get the best price, terms, and conditions.
          </p>
        </div>
        <div className="image-content">
          <img
            src={require('../assets/images/part5.jpg')}
            alt="How It Works"
            className="image-placeholder"
          />
        </div>
      </div>

      {/* Section 6 - Text Right, Image Left */}
      <div className="content-image-section text-right animate-on-scroll">
        <div className="image-content">
          <img
            src={require('../assets/images/part6.jpg')}
            alt="Why Choose Us?"
            className="image-placeholder"
          />
        </div>
        <div className="text-content">
          <h2>6. We help hire an expert home inspector</h2>
          <p>
            We need to inspect any house you want to buy, making sure it has no structural or major defects. Our agents review the inspection report with you fully before you make your final decision.
          </p>
        </div>
      </div>

      {/* Section 7 - Text Left, Image Right */}
      <div className="content-image-section animate-on-scroll">
        <div className="text-content">
          <h2>7. We help you to get your mortgage commitment</h2>
          <p>
            Our mortgage brokers help you to get your mortgage locked in. A mortgage approval is not enough for getting a loan on closing. You must get your mortgage commitment to make sure your loan is secured on the closing date.
          </p>
        </div>
        <div className="image-content">
          <img
            src={require('../assets/images/part7.jpg')}
            alt="How It Works"
            className="image-placeholder"
          />
        </div>
      </div>

      {/* Section 8 - Text Right, Image Left */}
      <div className="content-image-section text-right animate-on-scroll">
        <div className="image-content">
          <img
            src={require('../assets/images/part8.jpg')}
            alt="Why Choose Us?"
            className="image-placeholder"
          />
        </div>
        <div className="text-content">
          <h2>8. We help you to finalize your home purchase, hassle-free</h2>
          <p>
            After you firmed up your home purchase, there's still lots of work that needs to be done to finalize the closing. We help you navigate all closing steps in coordination with lawyers, the seller's agent, the home inspector, lenders, and others to make sure everything gets executed precisely and without error or inconvenience to you.
          </p>
        </div>
        
      </div>
      <div className='under-steps'>
      <a className='read-more-link'>Receive $5000 Rebate</a></div>
      </div>
      {/* Google Reviews Section */}
      <div className="google-reviews-section">
        <h2>Our Google Reviews</h2>
        <GoogleReviews />
      </div>

      {/* Counter Section */}
      <div className="counter-section">
        <h2>Results</h2>
        <div className="counter-row">
          {counters.slice(0, 3).map((counter, index) => (
            <div key={index} className="counter-box">
              <img src={counter.icon} alt={`icon-${index + 1}`} className="counter-icon" />
              <h3>{counter.value}</h3>
              <p>{counter.text}</p>
            </div>
          ))}
        </div>
        <div className="counter-row">
          {counters.slice(3, 5).map((counter, index) => (
            <div key={index} className="counter-box">
              <img src={counter.icon} alt={`icon-${index + 1}`} className="counter-icon" />
              <h3>{counter.value}</h3>
              <p>{counter.text}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Featured Listings */}
      <h2 className="featured-listings-heading">Modern Solution Listings</h2>
      <ListingWidget />

      

      {/* City Links Section */}
      <div className="city-links-section">
        <CityWidget />
      </div>

   {/* Toronto Star Section */}
<div className="toronto-star-wrapper" style={{ display: 'flex', justifyContent: 'center' }}>
  <div className="toronto-star-container" style={{ maxWidth: '1500px', display: 'flex', gap: '1rem', alignItems: 'center' }}>
    <div className="home-page-toronto-star" style={{ flex: '1' }}>
      <img src={thestarLogo} alt="The Star Logo" className="thestar-logo" />
      <h2 className="toronto-star-heading">Modern Solution Featured in Toronto Star</h2>
      <p className="toronto-star-paragraph">
        In a real estate world where outdated commission rates have long been the norm,
        Modern Solution Realty Inc. is proud to offer a refreshing and cost-effective
        alternative.
        
        Traditional commission structures, often hovering around five to six per cent, have
        burdened home sellers with exorbitant fees. For instance, selling a $1,000,000 home with
        a standard five per cent commission could cost a staggering $50,000 — an expense that
        seems increasingly unreasonable in today’s dynamic market.
      </p>
      <a
        href="https://www.thestar.com/sponsored-sections/a-modern-solution-approach-to-real-estate-with-one-per-cent-commission/article_1ca3e32e-6eec-11ef-84ea-53f224f162c8.html"
        target="_blank"
        rel="noopener noreferrer"
        className="read-more-link"
      >
        Read More
      </a>
    </div>

    {/* New Photo Column */}
    <div className="toronto-star-image-column" style={{ flex: '0.4', paddingLeft: '1rem' }}>
      <img src={require('../assets/images/thestar-office.webp')} alt="The Star Office" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
    </div>
  </div>
</div>






      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default Buy;
