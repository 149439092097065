import React, { useEffect } from 'react';
import Lottie from 'react-lottie';
import animationData from '../animations/email-animation.json';
import ListingWidget from './ListingWidget'; // Make sure to adjust the import path based on your project structure
import './ThankYou.css';

const ThankYou = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    // Function to inject the Google Tag event snippet
    const addGoogleTagEvent = () => {
      const script = document.createElement('script');
      script.innerHTML = `
        gtag('event', 'conversion', {
            'send_to': 'AW-16529115435/q3drCNelraYZEKuS2ck9',
            'value': 1.0,
            'currency': 'CAD'
        });
      `;
      document.body.appendChild(script);
    };

    addGoogleTagEvent();
  }, []); // Empty dependency array ensures this runs once after the initial render

  return (
    <div className="thank-you-page">
    <div className="thank-you">
      <h1>Thank You For Your Submission!</h1>
      <p>We have received your inquiry. A local real estate agent will be in touch with you shortly.</p>
      <div className="lottie-container">
        <Lottie options={defaultOptions} height={300} width={300} />
      </div>
      <div className="info-boxes">
        <a href="/buying-process" className="info-box">
          <h2>Buying Process</h2>
          <div className="divider"></div>
          <p>Are you buying a home? Read about the buying process here.</p>
          <button>Learn More</button>
        </a>
        <a href="/selling-process" className="info-box">
          <h2>Selling Process</h2>
          <div className="divider"></div>
          <p>Are you selling your home? Read about the selling process here. </p>
          <button>Learn More</button>
        </a>
        <a href="/commission-explained" className="info-box">
          <h2>Commission Explained</h2>
          <div className="divider"></div>
          <p>Read about different commission structures for realtors.</p>
          <button>Learn More</button>
        </a>
      </div>
    </div>
    </div>
  );
};

export default ThankYou;
