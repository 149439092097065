import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Articles.css';
import { Helmet } from 'react-helmet';
import Footer from './Footer'; // Import the footer component

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  // Function to create a slug from a string
  const createSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, '') // Remove all non-alphanumeric characters except spaces and hyphens
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/-+/g, '-') // Replace multiple hyphens with a single hyphen
      .replace(/^-|-$/g, ''); // Trim hyphens from the start and end
  };

  const createExcerpt = (text, limit) => {
    if (text.length <= limit) return text;
    const excerpt = text.slice(0, limit);
    const lastPeriod = excerpt.lastIndexOf('.');
    return lastPeriod !== -1 ? excerpt.slice(0, lastPeriod + 1) : excerpt + '...';
  };

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const articleFiles = [
          'article1.json',
          'article2.json',
          'article3.json',
          'article4.json',
          'article5.json',
          'article6.json',
          'article7.json',
          'article8.json',
          'article9.json',
          'article10.json'
        ];
        const fetchedArticles = await Promise.all(
          articleFiles.map((file) =>
            fetch(`${process.env.PUBLIC_URL}/articles/${file}`).then((res) =>
              res.json()
            )
          )
        );

        const articlesWithSlugs = fetchedArticles.map(article => ({
          ...article,
          slug: createSlug(article.title)
        }));

        // Sort articles by date (newest first)
        const sortedArticles = articlesWithSlugs.sort((a, b) => new Date(b.date) - new Date(a.date));
        setArticles(sortedArticles);
        setFilteredArticles(sortedArticles);

        // Extract all unique categories
        const uniqueCategories = [...new Set(fetchedArticles.map(article => article.category))];
        setCategories(uniqueCategories);
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };
    fetchArticles();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const results = articles.filter(article =>
        article.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredArticles(results);
    } else if (selectedCategory) {
      const results = articles.filter(article =>
        article.category === selectedCategory
      );
      setFilteredArticles(results);
    } else {
      setFilteredArticles(articles);
    }
  }, [searchTerm, selectedCategory, articles]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setSelectedCategory(''); // Reset category selection when searching
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSearchTerm(''); // Reset search term when a category is selected
  };

  const handleViewAll = () => {
    setFilteredArticles(articles);
    setSelectedCategory('');
    setSearchTerm('');
  };

  return (
    <div className='page-container'>
    <div className='articles-container'>
      <Helmet>
        <title>Modern Solution Realty - Articles</title>
      </Helmet>
      <div className='sidebar'>
        <div className='recent-articles'>
          <h3>Recent Articles</h3>
          <hr className="sidebar-divider" />
          {articles.slice(0, 3).map((article, index) => (
            <Link
              key={index}
              to={`/articles/${article.slug}`}
              className="sidebar-item"
            >
              <div>{article.title}</div>
              <div className='article-date'>{article.date}</div>
            </Link>
          ))}
        </div>
        <button
          className='view-all-button'
          onClick={handleViewAll}
        >
          View All Articles
        </button>
        <div className='categories'>
          <h3>Categories</h3>
          <hr className="sidebar-divider" />
          {categories.length > 0 ? (
            categories.map((category, index) => (
              <div
                key={index}
                className={`category-item ${selectedCategory === category ? 'selected' : ''}`}
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </div>
            ))
          ) : (
            <p>No categories found.</p>
          )}
        </div>
        <div className='search-section'>
          <h3>Search</h3>
          <hr className="sidebar-divider" />
          <input
            type='text'
            placeholder='Search articles...'
            value={searchTerm}
            onChange={handleSearch}
            className='search-bar'
          />
        </div>
      </div>
      <div className='content'>
          {searchTerm && filteredArticles.length === 0 ? (
            <p>No results found for "{searchTerm}".</p>
          ) : (
            filteredArticles.map((article, index) => (
              <div key={index} className='article-preview'>
                <Link to={`/articles/${article.slug}`}>
                  <h2>{article.title}</h2>
                </Link>
                <div className='article-category'>{article.category}</div>
                <div className='article-date'>{article.date}</div>
                <p dangerouslySetInnerHTML={{ __html: createExcerpt(article.content, 400) }}></p>
                <Link to={`/articles/${article.slug}`} className='read-more-button'>Read More</Link>
              </div>
            ))
          )}
        </div>
      
    </div>
    <Footer />
    </div>
    
  
  );
  
};

export default Articles;
