import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './NavbarLandingPage.css';
import logo from '../logo.webp';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const NavbarLandingPage = ({ detectedCity }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isScrolled, setScrolled] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const toggleDropdownMenu = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const closeDropdownMenu = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrolled(offset > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Track window size to determine screen size
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1230);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set the initial value on load

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Dynamic search link based on detected city
  const searchLink = detectedCity ? `/listings?city=${detectedCity}` : '/listings';

  return (
    <nav className={`navbar-home ${isScrolled ? 'navbar-home-scrolled' : ''}`}>
      <div className="navbar-home-container">
        {/* Left Section */}
        <div className="navbar-home-left">
          {!isSmallScreen && (
            <NavLink to="/" className="navbar-home-logo-link">
              <img
                src={logo}
                alt="Modern Solution Realty"
                className="navbar-home-logo-image"
              />
            </NavLink>
          )}
        </div>

        {/* Center Section */}
        <div className="navbar-home-center">
          <ul className="navbar-home-nav-links">
            <li>
              <NavLink to="/buy" activeClassName="navbar-home-active-link" onClick={closeDropdownMenu}>
                Buy
              </NavLink>
            </li>
            <li>
              <NavLink to="/sell" activeClassName="navbar-home-active-link" onClick={closeDropdownMenu}>
                Sell
              </NavLink>
            </li>
            {/* Dynamic search link */}
            <li>
              <NavLink to={searchLink} activeClassName="navbar-home-active-link" onClick={closeDropdownMenu}>
                Search
              </NavLink>
            </li>
            {/* Extra links only shown on desktop */}
            {!isSmallScreen && (
              <>
                <li>
                  <NavLink
                    to="/commission-explained"
                    activeClassName="navbar-home-active-link"
                    onClick={closeDropdownMenu}
                  >
                    Commission Explained
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/articles" activeClassName="navbar-home-active-link" onClick={closeDropdownMenu}>
                    Articles
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact" activeClassName="navbar-home-active-link" onClick={closeDropdownMenu}>
                    Contact
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>

        {/* Right Section */}
        <div className="navbar-home-right">
          {isSmallScreen ? (
            <>
              {/* Dropdown toggle */}
              <div className="navbar-home-dropdown-toggle" onClick={toggleDropdownMenu}>
                <FontAwesomeIcon icon={isDropdownOpen ? faChevronUp : faChevronDown} />
              </div>
              {/* Contact Icons */}
              <div className="navbar-home-contact-icons">
                <a href="tel:+1234567890" className="icon-link" aria-label="Call us">
                  <FontAwesomeIcon icon={faPhone} className="landing-page-icon" />
                </a>
                <a href="mailto:email@example.com" className="icon-link" aria-label="Email us">
                  <FontAwesomeIcon icon={faEnvelope} className="landing-page-icon" />
                </a>
              </div>
            </>
          ) : (
            <>
              {/* Contact Icons */}
              <div className="navbar-home-contact-icons">
                <a href="tel:+1234567890" className="icon-link" aria-label="Call us">
                  <FontAwesomeIcon icon={faPhone} className="landing-page-icon" />
                </a>
                <a href="mailto:email@example.com" className="icon-link" aria-label="Email us">
                  <FontAwesomeIcon icon={faEnvelope} className="landing-page-icon" />
                </a>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <ul className={`navbar-home-dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
          <li>
            <NavLink
              to="/commission-explained"
              activeClassName="navbar-home-active-link"
              onClick={closeDropdownMenu}
            >
              Commission Explained
            </NavLink>
          </li>
          <li>
            <NavLink to="/articles" activeClassName="navbar-home-active-link" onClick={closeDropdownMenu}>
              Articles
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" activeClassName="navbar-home-active-link" onClick={closeDropdownMenu}>
              Contact
            </NavLink>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default NavbarLandingPage;
