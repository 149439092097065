import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './NavbarHome.css';
import logo from '../logo.webp';
import msrLogo from '../assets/images/ms.webp';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const NavbarHome = ({ detectedCity }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isScrolled, setScrolled] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const toggleDropdownMenu = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const closeDropdownMenu = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Track window size and switch logos
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1230);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set the initial value on load

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Dynamic search link based on detected city
  const searchLink = detectedCity ? `/listings?city=${detectedCity}` : '/listings';

  return (
    <nav className={`navbar-home ${isScrolled ? 'navbar-home-scrolled' : ''}`}>
      <div className="navbar-home-container">
        <div className="navbar-home-logo-container">
          <NavLink to="/" className="navbar-home-logo-link">
            <img
              src={isSmallScreen ? msrLogo : logo}
              alt="Modern Solution Realty"
              className="navbar-home-logo-image"
            />
          </NavLink>
        </div>

        <ul className="navbar-home-nav-links">
          <li><NavLink to="/buy" activeClassName="navbar-home-active-link" onClick={closeDropdownMenu}>Buy</NavLink></li>
          <li><NavLink to="/sell" activeClassName="navbar-home-active-link" onClick={closeDropdownMenu}>Sell</NavLink></li>
          {/* Dynamic search link */}
          <li><NavLink to={searchLink} activeClassName="navbar-home-active-link" onClick={closeDropdownMenu}>Search</NavLink></li>
          <li className="navbar-home-extra-link"><NavLink to="/commission-explained" activeClassName="navbar-home-active-link" onClick={closeDropdownMenu}>Commission Explained</NavLink></li>
          <li className="navbar-home-extra-link"><NavLink to="/articles" activeClassName="navbar-home-active-link" onClick={closeDropdownMenu}>Articles</NavLink></li>
          <li className="navbar-home-dropdown-toggle" onClick={toggleDropdownMenu}>
            <FontAwesomeIcon icon={isDropdownOpen ? faChevronUp : faChevronDown} />
          </li>
        </ul>

        {isDropdownOpen && (
          <ul className={`navbar-home-dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
            <li><NavLink to="/commission-explained" activeClassName="navbar-home-active-link" onClick={closeDropdownMenu}>Commission Explained</NavLink></li>
            <li><NavLink to="/articles" activeClassName="navbar-home-active-link" onClick={closeDropdownMenu}>Articles</NavLink></li>
            <li><NavLink to="/contact" activeClassName="navbar-home-active-link" onClick={closeDropdownMenu}>Contact</NavLink></li>
          </ul>
        )}
      </div>
    </nav>
  );
};

export default NavbarHome;
