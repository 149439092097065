export const neighborhoods = [{ name: 'Zurich', city: 'Bluewater' },
  { name: 'Yorkdale-Glen Park', city: 'Toronto' },
  { name: 'York University Heights', city: 'Toronto' },
  { name: 'Yonge-St. Clair', city: 'Toronto' },
  { name: 'Yonge-Eglinton', city: 'Toronto' },
  { name: 'Yeoville', city: 'Hamilton' },
  { name: 'Wyevale', city: 'Tiny' },
  { name: 'Wyecombe', city: 'Norfolk' },
  { name: 'Wyebridge', city: 'Tiny' },
  { name: 'Wychwood', city: 'Toronto' },
  { name: 'Woodville', city: 'Kawartha Lakes' },
  { name: 'Woodstock - South', city: 'Tillsonburg' },
  { name: 'Woodlands', city: 'Pickering' },
  { name: 'Woodland Hill', city: 'Newmarket' },
  { name: 'Woodbine-Lumsden', city: 'Toronto' },
  { name: 'Woodbine Corridor', city: 'Toronto' },
  { name: 'Woburn', city: 'Toronto' },
  { name: 'Wismer', city: 'Markham' },
  { name: 'Winona Park', city: 'Hamilton' },
  { name: 'Winona', city: 'Hamilton' },
  { name: 'Wingham', city: 'North Huron' },
  { name: 'Windsor', city: 'Windsor' },
  { name: 'Windham Centre', city: 'Norfolk' },
  { name: 'Windfields', city: 'Oshawa' },
  { name: 'Wilsonville', city: 'Norfolk' },
  { name: 'Willowridge-Martingrove-Richview', city: 'Toronto' },
  { name: 'Willowdale West', city: 'Toronto' },
  { name: 'Willowdale East', city: 'Toronto' },
  { name: 'Willmott', city: 'Milton' },
  { name: 'Williamsburg', city: 'Whitby' },
  { name: 'Wheatley', city: 'Chatham-Kent' },
  { name: 'Wexford-Maryvale', city: 'Toronto' },
  { name: 'Weston-Pellam Park', city: 'Toronto' },
  { name: 'Weston', city: 'Toronto' },
  { name: 'Westminster-Branson', city: 'Toronto' },
  { name: 'Westgate', city: 'Brampton' },
  { name: 'Westdale', city: 'Hamilton' },
  { name: 'Westcliffe', city: 'Hamilton' },
  { name: 'Westbrook', city: 'Richmond Hill' },
  { name: 'West Woodbridge', city: 'Vaughan' },
  { name: 'West Willow Woods', city: 'Guelph' },
  { name: 'West Shore', city: 'Pickering' },
  { name: 'West Shore', city: 'Severn' },
  { name: 'West Oak Trails', city: 'Oakville' },
  { name: 'West Lorne', city: 'West Elgin' },
  { name: 'West Humber-Clairville', city: 'Toronto' },
  { name: 'West Hill', city: 'Toronto' },
  { name: 'West Carleton', city: 'Ottawa' },
  { name: 'West Bayfield', city: 'Barrie' },
  { name: 'Wellington', city: 'Barrie' },
  { name: 'Wellington', city: 'Prince Edward County' },
  { name: 'Waverley', city: 'Guelph' },
  { name: 'Waverley', city: 'Tay' },
  { name: 'Waubaushene', city: 'Tay' },
  { name: 'Watford', city: 'Warwick' },
  { name: 'Waterfront Communities C8', city: 'Toronto' },
  { name: 'Waterfront Communities C1', city: 'Toronto' },
  { name: 'Waterford', city: 'Norfolk' },
  { name: 'Waterdown', city: 'Hamilton' },
  { name: 'Washago', city: 'Severn' },
  { name: 'Wasaga Beach', city: 'Wasaga Beach' },
  { name: 'Warminster', city: 'Oro-Medonte' },
  { name: 'Warkworth', city: 'Trent Hills' },
  { name: 'Wardsville', city: 'Southwest Middlesex' },
  { name: 'Walton', city: 'Huron East' },
  { name: 'Walsingham', city: 'Norfolk' },
  { name: 'Walsh', city: 'Norfolk' },
  { name: 'Wallacetown', city: 'Dutton/Dunwich' },
  { name: 'Wallaceburg', city: 'Chatham-Kent' },
  { name: 'Walker', city: 'Milton' },
  { name: 'Walden', city: 'Lambton Shores' },
  { name: 'Vittoria', city: 'Norfolk' },
  { name: 'Virginia', city: 'Georgina' },
  { name: 'Vinegar Hill', city: 'Markham' },
  { name: 'Vincent', city: 'Hamilton' },
  { name: 'Villages of Glancaster', city: 'Hamilton' },
  { name: 'Village Green-South Unionville', city: 'Markham' },
  { name: 'Village East', city: 'Pickering' },
  { name: 'Village', city: 'Guelph' },
  { name: 'Villa Nova', city: 'Norfolk' },
  { name: 'Vienna', city: 'Bayham' },
  { name: 'Victoria Village', city: 'Toronto' },
  { name: 'Victoria Square', city: 'Markham' },
  { name: 'Victoria Manor-Jennings Gate', city: 'Markham' },
  { name: 'Victoria Harbour', city: 'Tay' },
  { name: 'Vellore Village', city: 'Vaughan' },
  { name: 'Vaughan Grove', city: 'Vaughan' },
  { name: 'Vaughan Corporate Centre', city: 'Vaughan' },
  { name: 'Varna', city: 'Bluewater' },
  { name: 'Vanier', city: 'Oshawa' },
  { name: 'Vanessa', city: 'Norfolk' },
  { name: 'Valley East', city: 'Greater Sudbury' },
  { name: 'Vales of Castlemore North', city: 'Brampton' },
  { name: 'Vales of Castlemore', city: 'Brampton' },
  { name: 'Uxbridge', city: 'Uxbridge' },
  { name: 'Uptown Core', city: 'Oakville' },
  { name: 'Uptown', city: 'Burlington' },
  { name: 'Uplands', city: 'Vaughan' },
  { name: 'University', city: 'Toronto' },
  { name: 'Unionville', city: 'Markham' },
  { name: 'Union', city: 'Central Elgin' },
  { name: 'Tyandaga', city: 'Burlington' },
  { name: 'Two Rivers', city: 'Guelph' },
  { name: 'Twenty Place', city: 'Hamilton' },
  { name: 'Turkey Point', city: 'Norfolk' },
  { name: 'Trinity-Bellwoods', city: 'Toronto' },
  { name: 'Trenholme', city: 'Hamilton' },
  { name: 'Trafalgar', city: 'Milton' },
  { name: 'Townsend', city: 'Norfolk' },
  { name: 'Town Centre', city: 'Pickering' },
  { name: 'Tottenham', city: 'New Tecumseth' },
  { name: 'Toronto Gore Rural Estate', city: 'Brampton' },
  { name: 'Timberlea', city: 'Milton' },
  { name: 'Tillsonburg', city: 'Tillsonburg' },
  { name: 'Tilbury', city: 'Chatham-Kent' },
  { name: 'Thornton', city: 'Essa' },
  { name: 'Thornlea', city: 'Markham' },
  { name: 'Thornhill', city: 'Markham' },
  { name: 'Thorner', city: 'Hamilton' },
  { name: 'Thorndale', city: 'Thames Centre' },
  { name: 'Thorncliffe Park', city: 'Toronto' },
  { name: 'Thornbury', city: 'Blue Mountains' },
  { name: 'Thistletown-Beaumonde Heights', city: 'Toronto' },
  { name: 'Thedford', city: 'Lambton Shores' },
  { name: 'The Beaches', city: 'Toronto' },
  { name: 'Thamesville', city: 'Chatham-Kent' },
  { name: 'Thamesford', city: 'Zorra' },
  { name: 'Templemead', city: 'Hamilton' },
  { name: 'Teeterville', city: 'Norfolk' },
  { name: 'Taunton North', city: 'Whitby' },
  { name: 'Taunton', city: 'Oshawa' },
  { name: 'Tansley', city: 'Burlington' },
  { name: 'Tam O\'Shanter-Sullivan', city: 'Toronto' },
  { name: 'Talbotville', city: 'Southwold' },
  { name: 'SW', city: 'St. Thomas' },
  { name: 'SW', city: 'Strathroy-Caradoc' },
  { name: 'SW', city: 'Chatham-Kent' },
  { name: 'Sutton & Jackson\'s Point', city: 'Georgina' },
  { name: 'Sunninghill', city: 'Hamilton' },
  { name: 'Sunnidale', city: 'Barrie' },
  { name: 'Sunderland', city: 'Brock' },
  { name: 'Summerhill Estates', city: 'Newmarket' },
  { name: 'Sugarbush', city: 'Oro-Medonte' },
  { name: 'Sudbury', city: 'Greater Sudbury' },
  { name: 'Stroud', city: 'Innisfil' },
  { name: 'Streetsville', city: 'Mississauga' },
  { name: 'Strathcona', city: 'Hamilton' },
  { name: 'Straffordville', city: 'Bayham' },
  { name: 'Stouffville', city: 'Whitchurch-Stouffville' },
  { name: 'Stoney Creek Mountain', city: 'Hamilton' },
  { name: 'Stoney Creek Industrial', city: 'Hamilton' },
  { name: 'Stoney Creek', city: 'Hamilton' },
  { name: 'Stonehaven-Wyndham', city: 'Newmarket' },
  { name: 'Stonegate-Queensway', city: 'Toronto' },
  { name: 'Stipley', city: 'Hamilton' },
  { name: 'Stinson', city: 'Hamilton' },
  { name: 'Stewarttown', city: 'Halton Hills' },
  { name: 'Stevensville', city: 'Fort Erie' },
  { name: 'Stephen Twp', city: 'South Huron' },
  { name: 'Steeles West Industrial', city: 'Vaughan' },
  { name: 'Steeles', city: 'Toronto' },
  { name: 'Stayner', city: 'Clearview' },
  { name: 'St. Williams', city: 'Norfolk' },
  { name: 'St. Joseph', city: 'Bluewater' },
  { name: 'St. Clair', city: 'Hamilton' },
  { name: 'St. Clair', city: 'St. Clair' },
  { name: 'St. Andrew-Windfields', city: 'Toronto' },
  { name: 'Springfield', city: 'Malahide' },
  { name: 'Sparta', city: 'Central Elgin' },
  { name: 'Southwold Town', city: 'Southwold' },
  { name: 'Southgate', city: 'Brampton' },
  { name: 'Southam', city: 'Hamilton' },
  { name: 'South ZZ', city: 'London' },
  { name: 'South Y', city: 'London' },
  { name: 'South X', city: 'London' },
  { name: 'South West', city: 'Ajax' },
  { name: 'South W', city: 'London' },
  { name: 'South V', city: 'London' },
  { name: 'South U', city: 'London' },
  { name: 'South T', city: 'London' },
  { name: 'South Shore', city: 'Barrie' },
  { name: 'South S', city: 'London' },
  { name: 'South Riverdale', city: 'Toronto' },
  { name: 'South Richvale', city: 'Richmond Hill' },
  { name: 'South R', city: 'London' },
  { name: 'South Q', city: 'London' },
  { name: 'South Parkdale', city: 'Toronto' },
  { name: 'South P', city: 'London' },
  { name: 'South O', city: 'London' },
  { name: 'South N', city: 'London' },
  { name: 'South MM', city: 'London' },
  { name: 'South Marysburgh', city: 'Prince Edward County' },
  { name: 'South M', city: 'London' },
  { name: 'South L', city: 'London' },
  { name: 'South K', city: 'London' },
  { name: 'South J', city: 'London' },
  { name: 'South I', city: 'London' },
  { name: 'South H', city: 'London' },
  { name: 'South GG', city: 'London' },
  { name: 'South G', city: 'London' },
  { name: 'South F', city: 'London' },
  { name: 'South East', city: 'Ajax' },
  { name: 'South E', city: 'London' },
  { name: 'South Dumfries', city: 'Brant' },
  { name: 'South D', city: 'London' },
  { name: 'South C', city: 'London' },
  { name: 'South Bruce Peninsula', city: 'South Bruce Peninsula' },
  { name: 'South Bruce', city: 'South Bruce' },
  { name: 'Sophiasburgh', city: 'Prince Edward County' },
  { name: 'Sonoma Heights', city: 'Vaughan' },
  { name: 'Snow Valley', city: 'Springwater' },
  { name: 'Snelgrove', city: 'Brampton' },
  { name: 'Snake Island', city: 'Georgina Islands' },
  { name: 'Singhampton', city: 'Clearview' },
  { name: 'Simcoe', city: 'Norfolk' },
  { name: 'Shoreacres', city: 'Burlington' },
  { name: 'Sherwood-Amberglen', city: 'Markham' },
  { name: 'Sherwood', city: 'Hamilton' },
  { name: 'Sheridan Park', city: 'Mississauga' },
  { name: 'Sheridan', city: 'Mississauga' },
  { name: 'Sheldon', city: 'Hamilton' },
  { name: 'Shelburne', city: 'Shelburne' },
  { name: 'Shedden', city: 'Southwold' },
  { name: 'Sharon', city: 'East Gwillimbury' },
  { name: 'Shanty Bay', city: 'Oro-Medonte' },
  { name: 'Scott', city: 'Milton' },
  { name: 'Schomberg', city: 'King' },
  { name: 'Scarborough Village', city: 'Toronto' },
  { name: 'Saugeen Shores', city: 'Saugeen Shores' },
  { name: 'Sarnia', city: 'Sarnia' },
  { name: 'Sandy Hollow', city: 'Barrie' },
  { name: 'Sandringham-Wellington North', city: 'Brampton' },
  { name: 'Sandringham-Wellington', city: 'Brampton' },
  { name: 'Samac', city: 'Oshawa' },
  { name: 'Rymal', city: 'Hamilton' },
  { name: 'Ryckmans', city: 'Hamilton' },
  { name: 'Rustic', city: 'Toronto' },
  { name: 'Rushdale', city: 'Hamilton' },
  { name: 'Rural Woodhouse', city: 'Norfolk' },
  { name: 'Rural Windham', city: 'Norfolk' },
  { name: 'Rural Whitchurch-Stouffville', city: 'Whitchurch-Stouffville' },
  { name: 'Rural Whitby', city: 'Whitby' },
  { name: 'Rural West Grey', city: 'West Grey' },
  { name: 'Rural West Elgin', city: 'West Elgin' },
  { name: 'Rural Wellington North', city: 'Wellington North' },
  { name: 'Rural Verulam', city: 'Kawartha Lakes' },
  { name: 'Rural Vaughan', city: 'Vaughan' },
  { name: 'Rural Uxbridge', city: 'Uxbridge' },
  { name: 'Rural Trent Hills', city: 'Trent Hills' },
  { name: 'Rural Tiny', city: 'Tiny' },
  { name: 'Rural Thames Centre', city: 'Thames Centre' },
  { name: 'Rural Tay', city: 'Tay' },
  { name: 'Rural Strathroy Caradoc', city: 'Strathroy-Caradoc' },
  { name: 'Rural Stoney Creek', city: 'Hamilton' },
  { name: 'Rural Springwater', city: 'Springwater' },
  { name: 'Rural Southwold', city: 'Southwold' },
  { name: 'Rural Southwest Middlesex', city: 'Southwest Middlesex' },
  { name: 'Rural Southgate', city: 'Southgate' },
  { name: 'Rural Somerville', city: 'Kawartha Lakes' },
  { name: 'Rural Smith-Ennismore-Lakefield', city: 'Smith-Ennismore-Lakefield' },
  { name: 'Rural Severn', city: 'Severn' },
  { name: 'Rural Scugog', city: 'Scugog' },
  { name: 'Rural Richmond Hill', city: 'Richmond Hill' },
  { name: 'Rural Ramara', city: 'Ramara' },
  { name: 'Rural Puslinch', city: 'Puslinch' },
  { name: 'Rural Port Hope', city: 'Port Hope' },
  { name: 'Rural Pickering', city: 'Pickering' },
  { name: 'Rural Otonabee-South Monaghan', city: 'Otonabee-South Monaghan' },
  { name: 'Rural Oshawa', city: 'Oshawa' },
  { name: 'Rural Oro-Medonte', city: 'Oro-Medonte' },
  { name: 'Rural Orford', city: 'Chatham-Kent' },
  { name: 'Rural Ops', city: 'Kawartha Lakes' },
  { name: 'Rural Oakville', city: 'Oakville' },
  { name: 'Rural Norwich', city: 'Norwich' },
  { name: 'Rural North Middlesex', city: 'North Middlesex' },
  { name: 'Rural North Kawartha', city: 'North Kawartha' },
  { name: 'Rural New Tecumseth', city: 'New Tecumseth' },
  { name: 'Rural Mulmur', city: 'Mulmur' },
  { name: 'Rural Mono', city: 'Mono' },
  { name: 'Rural Minto', city: 'Minto' },
  { name: 'Rural Middleton', city: 'Norfolk' },
  { name: 'Rural Middlesex Centre', city: 'Middlesex Centre' },
  { name: 'Rural Melancthon', city: 'Melancthon' },
  { name: 'Rural Meaford', city: 'Meaford' },
  { name: 'Rural Markham', city: 'Markham' },
  { name: 'Rural Mariposa', city: 'Kawartha Lakes' },
  { name: 'Rural Mapleton', city: 'Mapleton' },
  { name: 'Rural Manvers', city: 'Kawartha Lakes' },
  { name: 'Rural Malahide', city: 'Malahide' },
  { name: 'Rural Laxton', city: 'Kawartha Lakes' },
  { name: 'Rural King', city: 'King' },
  { name: 'Rural Innisfil', city: 'Innisfil' },
  { name: 'Rural Houghton', city: 'Norfolk' },
  { name: 'Rural Havelock-Belmont-Methuen', city: 'Havelock-Belmont-Methuen' },
  { name: 'Rural Halton Hills', city: 'Halton Hills' },
  { name: 'Rural Guelph/Eramosa', city: 'Guelph/Eramosa' },
  { name: 'Rural Grey Highlands', city: 'Grey Highlands' },
  { name: 'Rural Glanbrook', city: 'Hamilton' },
  { name: 'Rural Georgian Bluffs', city: 'Georgian Bluffs' },
  { name: 'Rural Galway-Cavendish and Harvey', city: 'Galway-Cavendish and Harvey' },
  { name: 'Rural Flamborough', city: 'Hamilton' },
  { name: 'Rural Fenelon', city: 'Kawartha Lakes' },
  { name: 'Rural Essa', city: 'Essa' },
  { name: 'Rural Erin', city: 'Erin' },
  { name: 'Rural Emily', city: 'Kawartha Lakes' },
  { name: 'Rural Eldon', city: 'Kawartha Lakes' },
  { name: 'Rural East Luther Grand Valley', city: 'East Luther Grand Valley' },
  { name: 'Rural East Gwillimbury', city: 'East Gwillimbury' },
  { name: 'Rural East Garafraxa', city: 'East Garafraxa' },
  { name: 'Rural Dutton/Dunwich', city: 'Dutton/Dunwich' },
  { name: 'Rural Dundas', city: 'Hamilton' },
  { name: 'Rural Douro-Dummer', city: 'Douro-Dummer' },
  { name: 'Rural Dalton', city: 'Kawartha Lakes' },
  { name: 'Rural Cramahe', city: 'Cramahe' },
  { name: 'Rural Clearview', city: 'Clearview' },
  { name: 'Rural Clarington', city: 'Clarington' },
  { name: 'Rural Chatsworth', city: 'Chatsworth' },
  { name: 'Rural Charlotteville', city: 'Norfolk' },
  { name: 'Rural Centre Wellington', city: 'Centre Wellington' },
  { name: 'Rural Central Elgin', city: 'Central Elgin' },
  { name: 'Rural Cavan Monaghan', city: 'Cavan Monaghan' },
  { name: 'Rural Carden', city: 'Kawartha Lakes' },
  { name: 'Rural Caledon', city: 'Caledon' },
  { name: 'Rural Burlington', city: 'Burlington' },
  { name: 'Rural Brock', city: 'Brock' },
  { name: 'Rural Brighton', city: 'Brighton' },
  { name: 'Rural Bradford West Gwillimbury', city: 'Bradford West Gwillimbury' },
  { name: 'Rural Blue Mountains', city: 'Blue Mountains' },
  { name: 'Rural Bexley', city: 'Kawartha Lakes' },
  { name: 'Rural Bayham', city: 'Bayham' },
  { name: 'Rural Barrie Southwest', city: 'Barrie' },
  { name: 'Rural Barrie Southeast', city: 'Barrie' },
  { name: 'Rural Aurora', city: 'Aurora' },
  { name: 'Rural Asphodel-Norwood', city: 'Asphodel-Norwood' },
  { name: 'Rural Ancaster', city: 'Hamilton' },
  { name: 'Rural Amaranth', city: 'Amaranth' },
  { name: 'Rural Alnwick/Haldimand', city: 'Alnwick/Haldimand' },
  { name: 'Rural Adjala-Tosorontio', city: 'Adjala-Tosorontio' },
  { name: 'Rural Adelaide Metcalfe', city: 'Adelaide Metcalfe' },
  { name: 'Runnymede-Bloor West Village', city: 'Toronto' },
  { name: 'Royal Orchard', city: 'Markham' },
  { name: 'Rougemount', city: 'Pickering' },
  { name: 'Rouge Woods', city: 'Richmond Hill' },
  { name: 'Rouge River Estates', city: 'Markham' },
  { name: 'Rouge Park', city: 'Pickering' },
  { name: 'Rouge Fairways', city: 'Markham' },
  { name: 'Rouge E11', city: 'Toronto' },
  { name: 'Rouge E10', city: 'Toronto' },
  { name: 'Rosemont', city: 'Adjala-Tosorontio' },
  { name: 'Roseland', city: 'Burlington' },
  { name: 'Rosedale-Moore Park', city: 'Toronto' },
  { name: 'Rosedale', city: 'Hamilton' },
  { name: 'Rosebank', city: 'Pickering' },
  { name: 'Rose', city: 'Burlington' },
  { name: 'Roncesvalles', city: 'Toronto' },
  { name: 'Rolston', city: 'Hamilton' },
  { name: 'Rolling Acres', city: 'Whitby' },
  { name: 'Rodney', city: 'West Elgin' },
  { name: 'Rockwood', city: 'Guelph/Eramosa' },
  { name: 'Rockcliffe-Smythe', city: 'Toronto' },
  { name: 'Riverdale', city: 'Hamilton' },
  { name: 'River Oaks', city: 'Oakville' },
  { name: 'Rideau', city: 'Ottawa' },
  { name: 'Richmond', city: 'Bayham' },
  { name: 'Rexdale-Kipling', city: 'Toronto' },
  { name: 'Regent Park', city: 'Toronto' },
  { name: 'Red Hill', city: 'Hamilton' },
  { name: 'Rayside-Balfour', city: 'Greater Sudbury' },
  { name: 'Raymerville', city: 'Markham' },
  { name: 'Rathwood', city: 'Mississauga' },
  { name: 'Randall', city: 'Hamilton' },
  { name: 'Rama First Nation', city: 'Rama First Nation 32' },
  { name: 'Raleigh', city: 'Hamilton' },
  { name: 'Raglan', city: 'Oshawa' },
  { name: 'Quinndale', city: 'Hamilton' },
  { name: 'Queensville', city: 'East Gwillimbury' },
  { name: 'Queen\'s Park', city: 'Barrie' },
  { name: 'Queen Street Corridor', city: 'Brampton' },
  { name: 'Pringle Creek', city: 'Whitby' },
  { name: 'Princeton', city: 'Blandford-Blenheim' },
  { name: 'Princess-Rosethorn', city: 'Toronto' },
  { name: 'Priceville', city: 'Grey Highlands' },
  { name: 'Prices Corners', city: 'Oro-Medonte' },
  { name: 'Pottageville', city: 'King' },
  { name: 'Port Whitby', city: 'Whitby' },
  { name: 'Port Stanley', city: 'Central Elgin' },
  { name: 'Port Severn', city: 'Severn' },
  { name: 'Port Rowan', city: 'Norfolk' },
  { name: 'Port Perry', city: 'Scugog' },
  { name: 'Port McNicoll', city: 'Tay' },
  { name: 'Port Hope', city: 'Port Hope' },
  { name: 'Port Glasgow', city: 'West Elgin' },
  { name: 'Port Franks', city: 'Lambton Shores' },
  { name: 'Port Dover', city: 'Norfolk' },
  { name: 'Port Credit', city: 'Mississauga' },
  { name: 'Port Burwell', city: 'Bayham' },
  { name: 'Port Bruce', city: 'Malahide' },
  { name: 'Port Albert', city: 'Ashfield-Colborne-Wawanosh' },
  { name: 'Pontypool', city: 'Kawartha Lakes' },
  { name: 'Plympton Wyoming', city: 'Plympton-Wyoming' },
  { name: 'Pleasant View', city: 'Toronto' },
  { name: 'Playter Estates-Danforth', city: 'Toronto' },
  { name: 'Plattsville', city: 'Blandford-Blenheim' },
  { name: 'Pinecrest', city: 'Oshawa' },
  { name: 'Pine Ridge', city: 'Guelph' },
  { name: 'Picton', city: 'Prince Edward County' },
  { name: 'Phelpston', city: 'Springwater' },
  { name: 'Penetanguishene', city: 'Penetanguishene' },
  { name: 'Pefferlaw', city: 'Georgina' },
  { name: 'Patterson', city: 'Vaughan' },
  { name: 'Parkwoods-Donalda', city: 'Toronto' },
  { name: 'Parkwood Gardens', city: 'Guelph' },
  { name: 'Parkview', city: 'Hamilton' },
  { name: 'Parkhill', city: 'North Middlesex' },
  { name: 'Paris', city: 'Brant' },
  { name: 'Palmerston-Little Italy', city: 'Toronto' },
  { name: 'Palmerston', city: 'Minto' },
  { name: 'Palmer', city: 'Burlington' },
  { name: 'Palgrave', city: 'Caledon' },
  { name: 'Palermo West', city: 'Oakville' },
  { name: 'Painswick South', city: 'Barrie' },
  { name: 'Painswick North', city: 'Barrie' },
  { name: 'Owen Sound', city: 'Owen Sound' },
  { name: 'Otterville', city: 'Norwich' },
  { name: 'Ottawa', city: 'Ottawa' },
  { name: 'Otonabee', city: 'Peterborough' },
  { name: 'Osgoode', city: 'Ottawa' },
  { name: 'Orwell', city: 'Malahide' },
  { name: 'Orono', city: 'Clarington' },
  { name: 'Orillia', city: 'Orillia' },
  { name: 'Orchard', city: 'Burlington' },
  { name: 'Orangeville', city: 'Orangeville' },
  { name: 'Onward Willow', city: 'Guelph' },
  { name: 'Omemee', city: 'Kawartha Lakes' },
  { name: 'Old University', city: 'Guelph' },
  { name: 'Old Oakville', city: 'Oakville' },
  { name: 'Old Milton', city: 'Milton' },
  { name: 'Old Markham Village', city: 'Markham' },
  { name: 'Observatory', city: 'Richmond Hill' },
  { name: 'Oakwood Village', city: 'Toronto' },
  { name: 'Oakwood', city: 'Kawartha Lakes' },
  { name: 'Oakridge', city: 'Toronto' },
  { name: 'Oakland', city: 'Brant' },
  { name: 'Oak Ridges Lake Wilcox', city: 'Richmond Hill' },
  { name: 'Oak Ridges', city: 'Richmond Hill' },
  { name: 'O\'Neill', city: 'Oshawa' },
  { name: 'O\'Connor-Parkview', city: 'Toronto' },
  { name: 'NW', city: 'St. Thomas' },
  { name: 'NW', city: 'Strathroy-Caradoc' },
  { name: 'Nottawa', city: 'Clearview' },
  { name: 'Norwood', city: 'Asphodel-Norwood' },
  { name: 'Norwich Town', city: 'Norwich' },
  { name: 'Northwood Park', city: 'Brampton' },
  { name: 'Northwood', city: 'Oshawa' },
  { name: 'Northwest Sandalwood Parkway', city: 'Brampton' },
  { name: 'Northwest Industrial Park', city: 'Guelph' },
  { name: 'Northwest Brampton', city: 'Brampton' },
  { name: 'Northwest Ajax', city: 'Ajax' },
  { name: 'Northwest', city: 'Barrie' },
  { name: 'Northglen', city: 'Oshawa' },
  { name: 'Northgate', city: 'Brampton' },
  { name: 'Northern Bruce Peninsula', city: 'Northern Bruce Peninsula' },
  { name: 'Northeast Ajax', city: 'Ajax' },
  { name: 'Northcrest', city: 'Peterborough' },
  { name: 'North St. James Town', city: 'Toronto' },
  { name: 'North Shore', city: 'Barrie' },
  { name: 'North Riverdale', city: 'Toronto' },
  { name: 'North Richvale', city: 'Richmond Hill' },
  { name: 'North End', city: 'Hamilton' },
  { name: 'Normanhurst', city: 'Hamilton' },
  { name: 'Norland', city: 'Kawartha Lakes' },
  { name: 'Norfolk', city: 'Norfolk' },
  { name: 'None', city: 'Ingersoll' },
  { name: 'Nobleton', city: 'King' },
  { name: 'Newtonbrook West', city: 'Toronto' },
  { name: 'Newtonbrook East', city: 'Toronto' },
  { name: 'Newcastle', city: 'Clarington' },
  { name: 'Newbury', city: 'Newbury' },
  { name: 'New Toronto', city: 'Toronto' },
  { name: 'New Sarum', city: 'Central Elgin' },
  { name: 'New Lowell', city: 'Clearview' },
  { name: 'New Glasgow', city: 'West Elgin' },
  { name: 'Neustadt', city: 'West Grey' },
  { name: 'Nepean', city: 'Ottawa' },
  { name: 'Nelson', city: 'Milton' },
  { name: 'NE', city: 'Strathroy-Caradoc' },
  { name: 'NE', city: 'St. Thomas' },
  { name: 'Nassagaweya', city: 'Milton' },
  { name: 'Nanticoke', city: 'Haldimand' },
  { name: 'Nairn', city: 'North Middlesex' },
  { name: 'Mt. Elgin', city: 'South-West Oxford' },
  { name: 'Mt Albert', city: 'East Gwillimbury' },
  { name: 'Mountview', city: 'Hamilton' },
  { name: 'Mountjoy Rural', city: 'Timmins' },
  { name: 'Mountainside', city: 'Burlington' },
  { name: 'Mount Salem', city: 'Malahide' },
  { name: 'Mount Pleasant West', city: 'Toronto' },
  { name: 'Mount Pleasant East', city: 'Toronto' },
  { name: 'Mount Olive-Silverstone-Jamestown', city: 'Toronto' },
  { name: 'Mount Hope', city: 'Hamilton' },
  { name: 'Mount Forest', city: 'Wellington North' },
  { name: 'Mount Elgin', city: 'South-West Oxford' },
  { name: 'Mount Dennis', city: 'Toronto' },
  { name: 'Mount Brydges', city: 'Strathroy-Caradoc' },
  { name: 'Mossley', city: 'Thames Centre' },
  { name: 'Moss Park', city: 'Toronto' },
  { name: 'Morriston', city: 'Puslinch' },
  { name: 'Morningside', city: 'Toronto' },
  { name: 'Moonstone', city: 'Oro-Medonte' },
  { name: 'Mono Mills', city: 'Caledon' },
  { name: 'Monaghan', city: 'Peterborough' },
  { name: 'Moffat', city: 'Milton' },
  { name: 'Mississauga Valleys', city: 'Mississauga' },
  { name: 'Minesing', city: 'Springwater' },
  { name: 'Mineola', city: 'Mississauga' },
  { name: 'Mimico', city: 'Toronto' },
  { name: 'Milton Heights', city: 'Milton' },
  { name: 'Milliken Mills West', city: 'Markham' },
  { name: 'Milliken Mills East', city: 'Markham' },
  { name: 'Milliken', city: 'Toronto' },
  { name: 'Millbrook', city: 'Cavan Monaghan' },
  { name: 'Mill Pond', city: 'Richmond Hill' },
  { name: 'Midland', city: 'Midland' },
  { name: 'Midhurst', city: 'Springwater' },
  { name: 'Middlesex Centre', city: 'Middlesex Centre' },
  { name: 'Middlemiss', city: 'Southwest Middlesex' },
  { name: 'Middlefield', city: 'Markham' },
  { name: 'Mewburn', city: 'Hamilton' },
  { name: 'Melbourne', city: 'Strathroy-Caradoc' },
  { name: 'Meaford', city: 'Meaford' },
  { name: 'Meadowvale Village', city: 'Mississauga' },
  { name: 'Meadowvale', city: 'Mississauga' },
  { name: 'Meadowlands', city: 'Hamilton' },
  { name: 'McQuesten', city: 'Hamilton' },
  { name: 'McLaughlin', city: 'Oshawa' },
  { name: 'McKillop Twp', city: 'Huron East' },
  { name: 'Markville', city: 'Markham' },
  { name: 'Markland Wood', city: 'Toronto' },
  { name: 'Markham Village', city: 'Markham' },
  { name: 'Markdale', city: 'Grey Highlands' },
  { name: 'Marchmont', city: 'Severn' },
  { name: 'Maple Leaf', city: 'Toronto' },
  { name: 'Maple', city: 'Vaughan' },
  { name: 'Manilla', city: 'Kawartha Lakes' },
  { name: 'Malvern', city: 'Toronto' },
  { name: 'Malton', city: 'Mississauga' },
  { name: 'Malahide', city: 'Malahide' },
  { name: 'Madoc', city: 'Brampton' },
  { name: 'Macassa', city: 'Hamilton' },
  { name: 'Lynhurst', city: 'Central Elgin' },
  { name: 'Lynden', city: 'Hamilton' },
  { name: 'Lynde Creek', city: 'Whitby' },
  { name: 'Lucan', city: 'Lucan Biddulph' },
  { name: 'Lorne Park', city: 'Mississauga' },
  { name: 'Loretto', city: 'Adjala-Tosorontio' },
  { name: 'Long Point', city: 'Norfolk' },
  { name: 'Long Branch', city: 'Toronto' },
  { name: 'Liverpool', city: 'Pickering' },
  { name: 'Little Portugal', city: 'Toronto' },
  { name: 'Little Lake', city: 'Barrie' },
  { name: 'Little Britain', city: 'Kawartha Lakes' },
  { name: 'Lisle', city: 'Adjala-Tosorontio' },
  { name: 'Lisgar', city: 'Mississauga' },
  { name: 'Lisgar', city: 'Hamilton' },
  { name: 'Lindsay', city: 'Kawartha Lakes' },
  { name: 'Limehouse', city: 'Halton Hills' },
  { name: 'Letitia Heights', city: 'Barrie' },
  { name: 'Legacy', city: 'Markham' },
  { name: 'Lefroy', city: 'Innisfil' },
  { name: 'Leaside', city: 'Toronto' },
  { name: 'Leamington', city: 'Leamington' },
  { name: 'Lawrence Park South', city: 'Toronto' },
  { name: 'Lawrence Park North', city: 'Toronto' },
  { name: 'Lawfield', city: 'Hamilton' },
  { name: 'LaSalle', city: 'Burlington' },
  { name: 'LaSalle', city: 'LaSalle' },
  { name: 'LaSalette', city: 'Norfolk' },
  { name: 'Larder/Virginiatown', city: 'Larder Lake' },
  { name: 'Lansing-Westgate', city: 'Toronto' },
  { name: 'Langton', city: 'Norfolk' },
  { name: 'Langstaff', city: 'Richmond Hill' },
  { name: 'Landsdale', city: 'Hamilton' },
  { name: 'Lambton Shores', city: 'Lambton Shores' },
  { name: 'Lambton Baby Point', city: 'Toronto' },
  { name: 'Lakeview Estates', city: 'Vaughan' },
  { name: 'Lakeview', city: 'Oshawa' },
  { name: 'Lakeview', city: 'Mississauga' },
  { name: 'Lakeshore', city: 'Barrie' },
  { name: 'Lakeshore', city: 'Hamilton' },
  { name: 'Lakeshore', city: 'Lakeshore' },
  { name: 'Lakefield', city: 'Smith-Ennismore-Lakefield' },
  { name: 'Lafontaine', city: 'Tiny' },
  { name: 'L\'Amoreaux', city: 'Toronto' },
  { name: 'Kortright Hills', city: 'Guelph' },
  { name: 'Komoka', city: 'Middlesex Centre' },
  { name: 'Kleinburg', city: 'Vaughan' },
  { name: 'Kirkfield', city: 'Kawartha Lakes' },
  { name: 'Kirkendall', city: 'Hamilton' },
  { name: 'Kintail', city: 'Ashfield-Colborne-Wawanosh' },
  { name: 'Kinmount', city: 'Kawartha Lakes' },
  { name: 'Kingsway South', city: 'Toronto' },
  { name: 'Kingsview Village-The Westway', city: 'Toronto' },
  { name: 'King City', city: 'King' },
  { name: 'Kincardine', city: 'Kincardine' },
  { name: 'Kilworth', city: 'Middlesex Centre' },
  { name: 'Kettle Point', city: 'Lambton Shores' },
  { name: 'Keswick South', city: 'Georgina' },
  { name: 'Keswick North', city: 'Georgina' },
  { name: 'Kernighan', city: 'Hamilton' },
  { name: 'Kentley', city: 'Hamilton' },
  { name: 'Kensington-Chinatown', city: 'Toronto' },
  { name: 'Kennedy Park', city: 'Toronto' },
  { name: 'Kennedy', city: 'Hamilton' },
  { name: 'Keelesdale-Eglinton West', city: 'Toronto' },
  { name: 'Kedron', city: 'Oshawa' },
  { name: 'Kanata', city: 'Ottawa' },
  { name: 'June Avenue', city: 'Guelph' },
  { name: 'Junction Area', city: 'Toronto' },
  { name: 'Jerseyville', city: 'Hamilton' },
  { name: 'Jerome', city: 'Hamilton' },
  { name: 'Jefferson', city: 'Richmond Hill' },
  { name: 'Janetville', city: 'Kawartha Lakes' },
  { name: 'Islington-City Centre West', city: 'Toronto' },
  { name: 'Islington Woods', city: 'Vaughan' },
  { name: 'Iroquois Ridge South', city: 'Oakville' },
  { name: 'Iroquois Ridge North', city: 'Oakville' },
  { name: 'Iroquoia Heights', city: 'Hamilton' },
  { name: 'Ionview', city: 'Toronto' },
  { name: 'Iona Station', city: 'Southwold' },
  { name: 'Iona', city: 'Southwold' },
  { name: 'Iona', city: 'Dutton/Dunwich' },
  { name: 'Innis-Shore', city: 'Barrie' },
  { name: 'Innerkip', city: 'East Zorra-Tavistock' },
  { name: 'Inglewood', city: 'Caledon' },
  { name: 'Ingersoll - South', city: 'Ingersoll' },
  { name: 'Ingersoll - North', city: 'Ingersoll' },
  { name: 'Ingersoll', city: 'Ingersoll' },
  { name: 'Industrial Sector', city: 'Hamilton' },
  { name: 'Inch Park', city: 'Hamilton' },
  { name: 'Ilderton', city: 'Middlesex Centre' },
  { name: 'Huttonville', city: 'Brampton' },
  { name: 'Hurontario', city: 'Mississauga' },
  { name: 'Huron-Kinloss', city: 'Huron-Kinloss' },
  { name: 'Huron Heights-Leslie Valley', city: 'Newmarket' },
  { name: 'Huntington', city: 'Hamilton' },
  { name: 'Humewood-Cedarvale', city: 'Toronto' },
  { name: 'Humbermede', city: 'Toronto' },
  { name: 'Humberlea-Pelmo Park W5', city: 'Toronto' },
  { name: 'Humberlea-Pelmo Park W4', city: 'Toronto' },
  { name: 'Humber Summit', city: 'Toronto' },
  { name: 'Humber Heights', city: 'Toronto' },
  { name: 'Howick Twp', city: 'Howick' },
  { name: 'Howard', city: 'Chatham-Kent' },
  { name: 'Horseshoe Valley', city: 'Oro-Medonte' },
  { name: 'Homeside', city: 'Hamilton' },
  { name: 'Holly', city: 'Barrie' },
  { name: 'Holland Landing', city: 'East Gwillimbury' },
  { name: 'Hockley', city: 'Adjala-Tosorontio' },
  { name: 'Historic Lakeshore Communities', city: 'Georgina' },
  { name: 'Hillsdale', city: 'Springwater' },
  { name: 'Hillsburgh', city: 'Erin' },
  { name: 'Hills of St Andrew', city: 'Aurora' },
  { name: 'Hillier', city: 'Prince Edward County' },
  { name: 'Hillcrest Village', city: 'Toronto' },
  { name: 'Hill Park', city: 'Hamilton' },
  { name: 'Highway 427', city: 'Brampton' },
  { name: 'Highland Creek', city: 'Toronto' },
  { name: 'Highbush', city: 'Pickering' },
  { name: 'High Park-Swansea', city: 'Toronto' },
  { name: 'High Park North', city: 'Toronto' },
  { name: 'Hiawatha First Nation', city: 'Hiawatha First Nation' },
  { name: 'Hensall', city: 'Bluewater' },
  { name: 'Henry Farm', city: 'Toronto' },
  { name: 'Heart Lake West', city: 'Brampton' },
  { name: 'Heart Lake East', city: 'Brampton' },
  { name: 'Heart Lake', city: 'Brampton' },
  { name: 'Headon', city: 'Burlington' },
  { name: 'Headford Business Park', city: 'Richmond Hill' },
  { name: 'Hay Twp', city: 'Bluewater' },
  { name: 'Hawkestone', city: 'Oro-Medonte' },
  { name: 'Havelock', city: 'Havelock-Belmont-Methuen' },
  { name: 'Hastings', city: 'Trent Hills' },
  { name: 'Harriston', city: 'Minto' },
  { name: 'Harrison', city: 'Milton' },
  { name: 'Harding', city: 'Richmond Hill' },
  { name: 'Hanover', city: 'Hanover' },
  { name: 'Hannon', city: 'Hamilton' },
  { name: 'Hanlon Industrial', city: 'Guelph' },
  { name: 'Hanlon Creek', city: 'Guelph' },
  { name: 'Hampton Heights', city: 'Hamilton' },
  { name: 'Hamilton Beach', city: 'Hamilton' },
  { name: 'Hallowell', city: 'Prince Edward County' },
  { name: 'Haldimand', city: 'Haldimand' },
  { name: 'Guthrie', city: 'Oro-Medonte' },
  { name: 'Gurnett', city: 'Hamilton' },
  { name: 'Guildwood', city: 'Toronto' },
  { name: 'Guelph South', city: 'Guelph' },
  { name: 'Grove East', city: 'Barrie' },
  { name: 'Grindstone', city: 'Burlington' },
  { name: 'Greenwood-Coxwell', city: 'Toronto' },
  { name: 'Greensville', city: 'Hamilton' },
  { name: 'Greensborough', city: 'Markham' },
  { name: 'Greeningdon', city: 'Hamilton' },
  { name: 'Greenford', city: 'Hamilton' },
  { name: 'Granton', city: 'Lucan Biddulph' },
  { name: 'Grange Hill East', city: 'Guelph' },
  { name: 'Grandview', city: 'Markham' },
  { name: 'Grand Valley', city: 'East Luther Grand Valley' },
  { name: 'Grand Bend', city: 'Lambton Shores' },
  { name: 'Grafton', city: 'Alnwick/Haldimand' },
  { name: 'Gowanstown', city: 'North Perth' },
  { name: 'Gourley', city: 'Hamilton' },
  { name: 'Gorham-College Manor', city: 'Newmarket' },
  { name: 'Goreway Drive Corridor', city: 'Brampton' },
  { name: 'Gore Industrial South', city: 'Brampton' },
  { name: 'Gore Industrial North', city: 'Brampton' },
  { name: 'Goderich Twp', city: 'Central Huron' },
  { name: 'Goderich Town', city: 'Goderich' },
  { name: 'Gloucester', city: 'Ottawa' },
  { name: 'Glenway Estates', city: 'Newmarket' },
  { name: 'Glenview', city: 'Hamilton' },
  { name: 'Glenfield-Jane Heights', city: 'Toronto' },
  { name: 'Glencoe', city: 'Southwest Middlesex' },
  { name: 'Glencairn', city: 'Adjala-Tosorontio' },
  { name: 'Glen Williams', city: 'Halton Hills' },
  { name: 'Glen Shields', city: 'Vaughan' },
  { name: 'Glen Abbey', city: 'Oakville' },
  { name: 'Gilkson', city: 'Hamilton' },
  { name: 'Gilford', city: 'Innisfil' },
  { name: 'Gilbert', city: 'Hamilton' },
  { name: 'Gibson', city: 'Hamilton' },
  { name: 'Gershome', city: 'Hamilton' },
  { name: 'Georgetown South', city: 'Halton Hills' },
  { name: 'Georgetown', city: 'Halton Hills' },
  { name: 'George South', city: 'South Bruce Peninsula' },
  { name: 'George North', city: 'South Bruce Peninsula' },
  { name: 'Genova', city: 'Lucan Biddulph' },
  { name: 'Garrison Village', city: 'Niagara-on-the-Lake' },
  { name: 'Garryowen', city: 'Chatham-Kent' },
  { name: 'Garafraxa Hill', city: 'East Luther Grand Valley' },
  { name: 'Gananoque', city: 'Gananoque' },
  { name: 'Gallanough', city: 'Vaughan' },
  { name: 'Gainsborough Twp', city: 'West Lincoln' },
  { name: 'Gage Park', city: 'Hamilton' },
  { name: 'Forest Glade', city: 'Windsor' },
  { name: 'Forest Hill South', city: 'Toronto' },
  { name: 'Forest Hill North', city: 'Toronto' },
  { name: 'Forest Heights', city: 'Hamilton' },
  { name: 'Forest Hill', city: 'Kitchener' },
  { name: 'Forest', city: 'Lambton Shores' },
  { name: 'Fonthill', city: 'Pelham' },
  { name: 'Flemingdon Park', city: 'Toronto' },
  { name: 'Flesherton', city: 'Grey Highlands' },
  { name: 'Flamborough East', city: 'Hamilton' },
  { name: 'Fisherville', city: 'Haldimand' },
  { name: 'Fergus', city: 'Centre Wellington' },
  { name: 'Feversham', city: 'Grey Highlands' },
  { name: 'Fenwick', city: 'Pelham' },
  { name: 'Fenelon Falls', city: 'Kawartha Lakes' },
  { name: 'Faraday Twp', city: 'Faraday' },
  { name: 'Fairport Beach', city: 'Pickering' },
  { name: 'Fairbank', city: 'Toronto' },
  { name: 'Fonthill', city: 'Pelham' },
  { name: 'Fenelon Falls', city: 'Kawartha Lakes' },];