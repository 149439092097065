import React from 'react';
import { FaCopy, FaEnvelope, FaFacebookF, FaTwitter, FaPinterestP, FaTimes } from 'react-icons/fa';
import './SharePopup.css';

const SharePopup = ({ onClose, listingUrl }) => {
  const shareMessage = "Check out this listing I just viewed on Modern Solution!";
  const shareOptions = [
    { name: 'Copy Link', icon: FaCopy, action: () => {
      navigator.clipboard.writeText(`${shareMessage} ${listingUrl}`);
      alert('Link copied to clipboard!');
    }},
    { name: 'Email', icon: FaEnvelope, action: () => window.open(`mailto:?subject=${encodeURIComponent(shareMessage)}&body=${encodeURIComponent(`${shareMessage} ${listingUrl}`)}`) },
    { name: 'Facebook', icon: FaFacebookF, action: () => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(listingUrl)}&quote=${encodeURIComponent(shareMessage)}`) },
    { name: 'Twitter', icon: FaTwitter, action: () => window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(listingUrl)}&text=${encodeURIComponent(shareMessage)}`) },
    { name: 'Pinterest', icon: FaPinterestP, action: () => window.open(`https://pinterest.com/pin/create/button/?url=${encodeURIComponent(listingUrl)}&description=${encodeURIComponent(shareMessage)}`) },
  ];

  return (
    <div className="share-popup-overlay" onClick={onClose}>
      <div className="share-popup-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button-x" onClick={onClose}>
          <FaTimes />
        </button>
        <h2>Share This Listing</h2>
        <div className="share-options">
          {shareOptions.map((option, index) => (
            <button key={index} className="share-option-button" onClick={option.action}>
              <option.icon /> {option.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SharePopup;