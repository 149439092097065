let _baseURL;
if(process.env.NODE_ENV !== "production") {
    _baseURL = "http://localhost:3002/api";
} else {
    _baseURL = 'https://backend.modernsolution.ca/api';
}

console.log("debug:" + process.env.NODE_ENV);
console.log(_baseURL);
export const baseURL = _baseURL;
export const recaptchaSiteKey = '6LeoeBIqAAAAAKP-0Vow859sRJ5m4nUYWWdd57cb';
