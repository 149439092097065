// src/data/listings.js
import listing1 from '../assets/images/1.webp';
import listing2 from '../assets/images/2.webp';
import listing3 from '../assets/images/3.webp';
import listing4 from '../assets/images/4.webp';
import listing5 from '../assets/images/5.webp';
import listing6 from '../assets/images/6.webp';

export const listings = [
  {
    id: 1,
    title: 'Beautiful Family Home',
    streetAddress: '123 Main St',
    address: '123 Main St, Toronto, Canada',
    price: '$500,000',
    mainImage: listing1,
    galleryImages: [listing1, listing2, listing3],
    propertyType: 'House',
    community: 'Downtown',
    city: 'Toronto',
    bedrooms: 3,
    bathrooms: 2,
    availability: 'Available',
  },
  {
    id: 2,
    title: 'Cozy Apartment',
    streetAddress: '456 Another St',
    address: '456 Another St, Ottawa, Canada',
    price: '$300,000',
    mainImage: listing2,
    galleryImages: [listing2, listing3, listing4],
    propertyType: 'Apartment',
    community: 'Centretown',
    city: 'Ottawa',
    bedrooms: 2,
    bathrooms: 1,
    availability: 'Sold',
  },
  {
    id: 3,
    title: 'Luxury Condo',
    streetAddress: '789 Fancy Blvd',
    address: '789 Fancy Blvd, Mississauga, Canada',
    price: '$750,000',
    mainImage: listing3,
    galleryImages: [listing3, listing4, listing5],
    propertyType: 'Condo',
    community: 'Square One',
    city: 'Mississauga',
    bedrooms: 2,
    bathrooms: 2,
    availability: 'Available',
  },
  {
    id: 4,
    title: 'Modern Villa',
    streetAddress: '1010 Modern Way',
    address: '1010 Modern Way, Brampton, Canada',
    price: '$1,200,000',
    mainImage: listing4,
    galleryImages: [listing4, listing5, listing6],
    propertyType: 'Villa',
    community: 'Springdale',
    city: 'Brampton',
    bedrooms: 4,
    bathrooms: 3,
    availability: 'Available',
  },
  {
    id: 5,
    title: 'Quaint Cottage',
    streetAddress: '11 Cottage Lane',
    address: '11 Cottage Lane, Hamilton, Canada',
    price: '$400,000',
    mainImage: listing5,
    galleryImages: [listing5, listing6, listing1],
    propertyType: 'Cottage',
    community: 'Westdale',
    city: 'Hamilton',
    bedrooms: 3,
    bathrooms: 1,
    availability: 'Sold',
  },
  {
    id: 6,
    title: 'Urban Loft',
    streetAddress: '22 Loft St',
    address: '22 Loft St, London, Canada',
    price: '$600,000',
    mainImage: listing6,
    galleryImages: [listing6, listing1, listing2],
    propertyType: 'Loft',
    community: 'SoHo',
    city: 'London',
    bedrooms: 1,
    bathrooms: 1,
    availability: 'Available',
  },
  {
    id: 7,
    title: 'Charming Bungalow',
    streetAddress: '123 Elm St',
    address: '123 Elm St, Kingston, Canada',
    price: '$520,000',
    mainImage: listing1,
    galleryImages: [listing1, listing2, listing3],
    propertyType: 'House',
    community: 'Sydenham',
    city: 'Kingston',
    bedrooms: 3,
    bathrooms: 2,
    availability: 'Available',
  },
  {
    id: 8,
    title: 'Cozy Apartment',
    streetAddress: '456 Maple Ave',
    address: '456 Maple Ave, Windsor, Canada',
    price: '$310,000',
    mainImage: listing2,
    galleryImages: [listing2, listing3, listing4],
    propertyType: 'Apartment',
    community: 'Walkerville',
    city: 'Windsor',
    bedrooms: 2,
    bathrooms: 1,
    availability: 'Sold',
  },
  {
    id: 9,
    title: 'Luxury Condo',
    streetAddress: '789 Pine St',
    address: '789 Pine St, Kitchener, Canada',
    price: '$760,000',
    mainImage: listing3,
    galleryImages: [listing3, listing4, listing5],
    propertyType: 'Condo',
    community: 'Downtown',
    city: 'Kitchener',
    bedrooms: 2,
    bathrooms: 2,
    availability: 'Available',
  },
  {
    id: 10,
    title: 'Modern Villa',
    streetAddress: '1010 Oak St',
    address: '1010 Oak St, Guelph, Canada',
    price: '$1,210,000',
    mainImage: listing4,
    galleryImages: [listing4, listing5, listing6],
    propertyType: 'Villa',
    community: 'Westminster Woods',
    city: 'Guelph',
    bedrooms: 4,
    bathrooms: 3,
    availability: 'Available',
  },
  {
    id: 11,
    title: 'Quaint Cottage',
    streetAddress: '11 Birch St',
    address: '11 Birch St, Thunder Bay, Canada',
    price: '$410,000',
    mainImage: listing5,
    galleryImages: [listing5, listing6, listing1],
    propertyType: 'Cottage',
    community: 'Fort William',
    city: 'Thunder Bay',
    bedrooms: 3,
    bathrooms: 1,
    availability: 'Sold',
  },
  {
    id: 12,
    title: 'Urban Loft',
    streetAddress: '22 Cedar St',
    address: '22 Cedar St, Barrie, Canada',
    price: '$610,000',
    mainImage: listing6,
    galleryImages: [listing6, listing1, listing2],
    propertyType: 'Loft',
    community: 'Downtown',
    city: 'Barrie',
    bedrooms: 1,
    bathrooms: 1,
    availability: 'Available',
  },
  {
    id: 13,
    title: 'Beautiful Family Home',
    streetAddress: '789 Chestnut St',
    address: '789 Chestnut St, Oshawa, Canada',
    price: '$530,000',
    mainImage: listing1,
    galleryImages: [listing1, listing2, listing3],
    propertyType: 'House',
    community: 'Northglen',
    city: 'Oshawa',
    bedrooms: 3,
    bathrooms: 2,
    availability: 'Available',
  },
  {
    id: 14,
    title: 'Cozy Apartment',
    streetAddress: '456 Oak St',
    address: '456 Oak St, Markham, Canada',
    price: '$320,000',
    mainImage: listing2,
    galleryImages: [listing2, listing3, listing4],
    propertyType: 'Apartment',
    community: 'Unionville',
    city: 'Markham',
    bedrooms: 2,
    bathrooms: 1,
    availability: 'Sold',
  },
  {
    id: 15,
    title: 'Luxury Condo',
    streetAddress: '789 Maple Ave',
    address: '789 Maple Ave, Vaughan, Canada',
    price: '$770,000',
    mainImage: listing3,
    galleryImages: [listing3, listing4, listing5],
    propertyType: 'Condo',
    community: 'Maple',
    city: 'Vaughan',
    bedrooms: 2,
    bathrooms: 2,
    availability: 'Available',
  },
  {
    id: 16,
    title: 'Modern Villa',
    streetAddress: '1010 Cedar St',
    address: '1010 Cedar St, Oakville, Canada',
    price: '$1,220,000',
    mainImage: listing4,
    galleryImages: [listing4, listing5, listing6],
    propertyType: 'Villa',
    community: 'Bronte',
    city: 'Oakville',
    bedrooms: 4,
    bathrooms: 3,
    availability: 'Available',
  },
  {
    id: 17,
    title: 'Quaint Cottage',
    streetAddress: '11 Elm St',
    address: '11 Elm St, Burlington, Canada',
    price: '$420,000',
    mainImage: listing5,
    galleryImages: [listing5, listing6, listing1],
    propertyType: 'Cottage',
    community: 'Roseland',
    city: 'Burlington',
    bedrooms: 3,
    bathrooms: 1,
    availability: 'Sold',
  },
  {
    id: 18,
    title: 'Urban Loft',
    streetAddress: '22 Birch St',
    address: '22 Birch St, Waterloo, Canada',
    price: '$620,000',
    mainImage: listing6,
    galleryImages: [listing6, listing1, listing2],
    propertyType: 'Loft',
    community: 'Uptown',
    city: 'Waterloo',
    bedrooms: 1,
    bathrooms: 1,
    availability: 'Available',
  },
  {
    id: 19,
    title: 'Beautiful Family Home',
    streetAddress: '123 Oak St',
    address: '123 Oak St, St. Catharines, Canada',
    price: '$540,000',
    mainImage: listing1,
    galleryImages: [listing1, listing2, listing3],
    propertyType: 'House',
    community: 'Port Dalhousie',
    city: 'St. Catharines',
    bedrooms: 3,
    bathrooms: 2,
    availability: 'Available',
  },
  {
    id: 20,
    title: 'Cozy Apartment',
    streetAddress: '456 Cedar St',
    address: '456 Cedar St, Niagara Falls, Canada',
    price: '$330,000',
    mainImage: listing2,
    galleryImages: [listing2, listing3, listing4],
    propertyType: 'Apartment',
    community: 'Fallsview',
    city: 'Niagara Falls',
    bedrooms: 2,
    bathrooms: 1,
    availability: 'Sold',
  },
  {
    id: 21,
    title: 'Luxury Condo',
    streetAddress: '789 Birch St',
    address: '789 Birch St, Peterborough, Canada',
    price: '$780,000',
    mainImage: listing3,
    galleryImages: [listing3, listing4, listing5],
    propertyType: 'Condo',
    community: 'Downtown',
    city: 'Peterborough',
    bedrooms: 2,
    bathrooms: 2,
    availability: 'Available',
  },
  {
    id: 22,
    title: 'Modern Villa',
    streetAddress: '1010 Elm St',
    address: '1010 Elm St, Sudbury, Canada',
    price: '$1,230,000',
    mainImage: listing4,
    galleryImages: [listing4, listing5, listing6],
    propertyType: 'Villa',
    community: 'South End',
    city: 'Sudbury',
    bedrooms: 4,
    bathrooms: 3,
    availability: 'Available',
  },
  {
    id: 23,
    title: 'Quaint Cottage',
    streetAddress: '11 Maple Ave',
    address: '11 Maple Ave, Whitby, Canada',
    price: '$430,000',
    mainImage: listing5,
    galleryImages: [listing5, listing6, listing1],
    propertyType: 'Cottage',
    community: 'Brooklin',
    city: 'Whitby',
    bedrooms: 3,
    bathrooms: 1,
    availability: 'Sold',
  },
  {
    id: 24,
    title: 'Urban Loft',
    streetAddress: '22 Pine St',
    address: '22 Pine St, Guelph, Canada',
    price: '$630,000',
    mainImage: listing6,
    galleryImages: [listing6, listing1, listing2],
    propertyType: 'Loft',
    community: 'Downtown',
    city: 'Guelph',
    bedrooms: 1,
    bathrooms: 1,
    availability: 'Available',
  },
  {
    id: 25,
    title: 'Beautiful Family Home',
    streetAddress: '123 Pine St',
    address: '123 Pine St, Brantford, Canada',
    price: '$550,000',
    mainImage: listing1,
    galleryImages: [listing1, listing2, listing3],
    propertyType: 'House',
    community: 'Holmedale',
    city: 'Brantford',
    bedrooms: 3,
    bathrooms: 2,
    availability: 'Available',
  },
  {
    id: 26,
    title: 'Cozy Apartment',
    streetAddress: '456 Elm St',
    address: '456 Elm St, Sarnia, Canada',
    price: '$340,000',
    mainImage: listing2,
    galleryImages: [listing2, listing3, listing4],
    propertyType: 'Apartment',
    community: 'Downtown',
    city: 'Sarnia',
    bedrooms: 2,
    bathrooms: 1,
    availability: 'Sold',
  },
  {
    id: 27,
    title: 'Luxury Condo',
    streetAddress: '789 Maple Ave',
    address: '789 Maple Ave, Cambridge, Canada',
    price: '$790,000',
    mainImage: listing3,
    galleryImages: [listing3, listing4, listing5],
    propertyType: 'Condo',
    community: 'Galt',
    city: 'Cambridge',
    bedrooms: 2,
    bathrooms: 2,
    availability: 'Available',
  },
  {
    id: 28,
    title: 'Modern Villa',
    streetAddress: '1010 Pine St',
    address: '1010 Pine St, Pickering, Canada',
    price: '$1,240,000',
    mainImage: listing4,
    galleryImages: [listing4, listing5, listing6],
    propertyType: 'Villa',
    community: 'Rougemount',
    city: 'Pickering',
    bedrooms: 4,
    bathrooms: 3,
    availability: 'Available',
  },
  {
    id: 29,
    title: 'Quaint Cottage',
    streetAddress: '11 Cedar St',
    address: '11 Cedar St, Milton, Canada',
    price: '$440,000',
    mainImage: listing5,
    galleryImages: [listing5, listing6, listing1],
    propertyType: 'Cottage',
    community: 'Old Milton',
    city: 'Milton',
    bedrooms: 3,
    bathrooms: 1,
    availability: 'Sold',
  },
  {
    id: 30,
    title: 'Urban Loft',
    streetAddress: '22 Oak St',
    address: '22 Oak St, Newmarket, Canada',
    price: '$640,000',
    mainImage: listing6,
    galleryImages: [listing6, listing1, listing2],
    propertyType: 'Loft',
    community: 'Downtown',
    city: 'Newmarket',
    bedrooms: 1,
    bathrooms: 1,
    availability: 'Available',
  },
  {
    id: 31,
    title: 'Beautiful Family Home',
    streetAddress: '123 Birch St',
    address: '123 Birch St, Aurora, Canada',
    price: '$560,000',
    mainImage: listing1,
    galleryImages: [listing1, listing2, listing3],
    propertyType: 'House',
    community: 'Aurora Heights',
    city: 'Aurora',
    bedrooms: 3,
    bathrooms: 2,
    availability: 'Available',
  },
  {
    id: 32,
    title: 'Cozy Apartment',
    streetAddress: '456 Pine St',
    address: '456 Pine St, Ajax, Canada',
    price: '$350,000',
    mainImage: listing2,
    galleryImages: [listing2, listing3, listing4],
    propertyType: 'Apartment',
    community: 'Southwood',
    city: 'Ajax',
    bedrooms: 2,
    bathrooms: 1,
    availability: 'Sold',
  },
  {
    id: 33,
    title: 'Luxury Condo',
    streetAddress: '789 Cedar St',
    address: '789 Cedar St, Burlington, Canada',
    price: '$800,000',
    mainImage: listing3,
    galleryImages: [listing3, listing4, listing5],
    propertyType: 'Condo',
    community: 'Aldershot',
    city: 'Burlington',
    bedrooms: 2,
    bathrooms: 2,
    availability: 'Available',
  },
  {
    id: 34,
    title: 'Modern Villa',
    streetAddress: '1010 Elm St',
    address: '1010 Elm St, Oshawa, Canada',
    price: '$1,250,000',
    mainImage: listing4,
    galleryImages: [listing4, listing5, listing6],
    propertyType: 'Villa',
    community: 'Eastdale',
    city: 'Oshawa',
    bedrooms: 4,
    bathrooms: 3,
    availability: 'Available',
  },
  {
    id: 35,
    title: 'Quaint Cottage',
    streetAddress: '11 Maple Ave',
    address: '11 Maple Ave, Barrie, Canada',
    price: '$450,000',
    mainImage: listing5,
    galleryImages: [listing5, listing6, listing1],
    propertyType: 'Cottage',
    community: 'Allandale',
    city: 'Barrie',
    bedrooms: 3,
    bathrooms: 1,
    availability: 'Sold',
  },
  {
    id: 36,
    title: 'Urban Loft',
    streetAddress: '22 Pine St',
    address: '22 Pine St, Brampton, Canada',
    price: '$650,000',
    mainImage: listing6,
    galleryImages: [listing6, listing1, listing2],
    propertyType: 'Loft',
    community: 'Downtown',
    city: 'Brampton',
    bedrooms: 1,
    bathrooms: 1,
    availability: 'Available',
  },
  {
    id: 37,
    title: 'Beautiful Family Home',
    streetAddress: '123 Elm St',
    address: '123 Elm St, Kingston, Canada',
    price: '$570,000',
    mainImage: listing1,
    galleryImages: [listing1, listing2, listing3],
    propertyType: 'House',
    community: 'Cataraqui North',
    city: 'Kingston',
    bedrooms: 3,
    bathrooms: 2,
    availability: 'Available',
  },
  {
    id: 38,
    title: 'Cozy Apartment',
    streetAddress: '456 Oak St',
    address: '456 Oak St, Markham, Canada',
    price: '$360,000',
    mainImage: listing2,
    galleryImages: [listing2, listing3, listing4],
    propertyType: 'Apartment',
    community: 'Cornell',
    city: 'Markham',
    bedrooms: 2,
    bathrooms: 1,
    availability: 'Sold',
  },
  {
    id: 39,
    title: 'Luxury Condo',
    streetAddress: '789 Maple Ave',
    address: '789 Maple Ave, Vaughan, Canada',
    price: '$810,000',
    mainImage: listing3,
    galleryImages: [listing3, listing4, listing5],
    propertyType: 'Condo',
    community: 'Woodbridge',
    city: 'Vaughan',
    bedrooms: 2,
    bathrooms: 2,
    availability: 'Available',
  },
  {
    id: 40,
    title: 'Modern Villa',
    streetAddress: '1010 Pine St',
    address: '1010 Pine St, Oakville, Canada',
    price: '$1,260,000',
    mainImage: listing4,
    galleryImages: [listing4, listing5, listing6],
    propertyType: 'Villa',
    community: 'Glen Abbey',
    city: 'Oakville',
    bedrooms: 4,
    bathrooms: 3,
    availability: 'Available',
  },
  {
    id: 41,
    title: 'Quaint Cottage',
    streetAddress: '11 Cedar St',
    address: '11 Cedar St, Thunder Bay, Canada',
    price: '$460,000',
    mainImage: listing5,
    galleryImages: [listing5, listing6, listing1],
    propertyType: 'Cottage',
    community: 'Northwood',
    city: 'Thunder Bay',
    bedrooms: 3,
    bathrooms: 1,
    availability: 'Sold',
  },
  {
    id: 42,
    title: 'Urban Loft',
    streetAddress: '22 Oak St',
    address: '22 Oak St, Barrie, Canada',
    price: '$660,000',
    mainImage: listing6,
    galleryImages: [listing6, listing1, listing2],
    propertyType: 'Loft',
    community: 'East End',
    city: 'Barrie',
    bedrooms: 1,
    bathrooms: 1,
    availability: 'Available',
  },
  {
    id: 43,
    title: 'Beautiful Family Home',
    streetAddress: '123 Birch St',
    address: '123 Birch St, Oshawa, Canada',
    price: '$580,000',
    mainImage: listing1,
    galleryImages: [listing1, listing2, listing3],
    propertyType: 'House',
    community: 'Samac',
    city: 'Oshawa',
    bedrooms: 3,
    bathrooms: 2,
    availability: 'Available',
  },
  {
    id: 44,
    title: 'Cozy Apartment',
    streetAddress: '456 Pine St',
    address: '456 Pine St, Markham, Canada',
    price: '$370,000',
    mainImage: listing2,
    galleryImages: [listing2, listing3, listing4],
    propertyType: 'Apartment',
    community: 'Greensborough',
    city: 'Markham',
    bedrooms: 2,
    bathrooms: 1,
    availability: 'Sold',
  },
  {
    id: 45,
    title: 'Luxury Condo',
    streetAddress: '789 Cedar St',
    address: '789 Cedar St, Burlington, Canada',
    price: '$820,000',
    mainImage: listing3,
    galleryImages: [listing3, listing4, listing5],
    propertyType: 'Condo',
    community: 'Tansley',
    city: 'Burlington',
    bedrooms: 2,
    bathrooms: 2,
    availability: 'Available',
  },
  {
    id: 46,
    title: 'Modern Villa',
    streetAddress: '1010 Elm St',
    address: '1010 Elm St, Oakville, Canada',
    price: '$1,270,000',
    mainImage: listing4,
    galleryImages: [listing4, listing5, listing6],
    propertyType: 'Villa',
    community: 'Clearview',
    city: 'Oakville',
    bedrooms: 4,
    bathrooms: 3,
    availability: 'Available',
  },
  {
    id: 47,
    title: 'Quaint Cottage',
    streetAddress: '11 Maple Ave',
    address: '11 Maple Ave, Oshawa, Canada',
    price: '$470,000',
    mainImage: listing5,
    galleryImages: [listing5, listing6, listing1],
    propertyType: 'Cottage',
    community: 'McLaughlin',
    city: 'Oshawa',
    bedrooms: 3,
    bathrooms: 1,
    availability: 'Sold',
  },
  {
    id: 48,
    title: 'Urban Loft',
    streetAddress: '22 Pine St',
    address: '22 Pine St, Thunder Bay, Canada',
    price: '$670,000',
    mainImage: listing6,
    galleryImages: [listing6, listing1, listing2],
    propertyType: 'Loft',
    community: 'Current River',
    city: 'Thunder Bay',
    bedrooms: 1,
    bathrooms: 1,
    availability: 'Available',
  },
  {
    id: 49,
    title: 'Beautiful Family Home',
    streetAddress: '123 Elm St',
    address: '123 Elm St, Kingston, Canada',
    price: '$590,000',
    mainImage: listing1,
    galleryImages: [listing1, listing2, listing3],
    propertyType: 'House',
    community: 'Bayridge West',
    city: 'Kingston',
    bedrooms: 3,
    bathrooms: 2,
    availability: 'Available',
  },
  {
    id: 50,
    title: 'Cozy Apartment',
    streetAddress: '456 Oak St',
    address: '456 Oak St, Markham, Canada',
    price: '$380,000',
    mainImage: listing2,
    galleryImages: [listing2, listing3, listing4],
    propertyType: 'Apartment',
    community: 'Berczy',
    city: 'Markham',
    bedrooms: 2,
    bathrooms: 1,
    availability: 'Sold',
  },
];
