import React, { useState } from 'react';
import './Contact.css';
import ContactForm from './ContactForm';
import { PiPhoneListThin, PiEnvelopeOpenThin, PiMapPinArea } from 'react-icons/pi';
import Footer from './Footer'; // Import the footer component
import { Helmet } from 'react-helmet';

const Contact = () => {
  const [openAccordion, setOpenAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const accordionData = [
    {
      icon: (
        <PiPhoneListThin
          className='icon-adjustment-large'
          style={{ color: 'black' }}
        />
      ), // Phone icon
      title: '(905) 897-5000',
      content:
        'We are available 24/7. Give us a call if you have any questions.',
    },
    {
      icon: (
        <PiEnvelopeOpenThin
          className='icon-adjustment-large'
          style={{ color: 'black' }}
        />
      ), // Envelope icon
      title: 'info@modernsolution.ca',
      content:
        'Send us an email and one of our agents will get back to you ASAP',
    },
    {
      icon: (
        <PiMapPinArea
          className='icon-adjustment-large'
          style={{ color: 'black' }}
        />
      ), // Envelope icon
      title: '3466 Mavis Rd Unit 1, Mississauga, ON L5C 1T8',
      content:
        'Come by and visit our office! We are open from Monday to Friday 9:00 AM - 5:00 PM',
    },
  ];

  return (
    <div className='contact-page-container'>
      <Helmet>
        <title>Modern Solution Realty - Contact</title>
      </Helmet>
      <div className='contact-container'>
      <div id='save-more' className='two-column-section'>
        <div className='left-column'>
          <h2>Save More with Modern Solution</h2>
          <h3 className='left-column-subtitle'>
            The real estate industry has changed. Here’s how we can pass the
            savings on to you.
          </h3>
          <div className='divider left-aligned-divider'></div>
          <div className='accordion'>
            {accordionData.map((item, index) => (
              <div key={index} className='accordion-item'>
                <div className='accordion-header'>
                  <div className='accordion-icon'>{item.icon}</div>
                  <div className='accordion-title'>
                    <a
                      href={
                        index === 0
                          ? 'tel:+19058975000'
                          : 'mailto:info@modernsolution.ca'
                      }
                      className='contact-link'
                    >
                      {item.title}
                    </a>
                  </div>
                  <div
                    className='accordion-arrow'
                    onClick={() => toggleAccordion(index)}
                  >
                    {openAccordion === index ? '↑' : '↓'}
                  </div>
                </div>
                {openAccordion === index && (
                  <div className='accordion-content'>
                    <p>{item.content}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className='right-column'>
          <h2 className='contact-form-title'>Send Us An Email.</h2>
          <h3 className='contact-form-subtitle'>
            Use the form below to send us an email. We'll get back to you as
            soon as we can.
          </h3>
          <div className='subcategory-divider'></div>
          <div className='contact-form'>
            <ContactForm />
          </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer /> {/* Add the footer component here */}
    </div>
    
  );
};

export default Contact;
