// src/components/Wishlist.js
import React, { useContext, useState, useEffect } from 'react';
import { listings } from '../data/listings';
import AuthContext from '../context/AuthContext';
import { FaHeart } from 'react-icons/fa';
import ImageSlider from './ImageSlider'; // Import ImageSlider
import './Wishlist.css'; // Make sure to create and update Wishlist.css for styles

const Wishlist = () => {
  const { user } = useContext(AuthContext);
  const [wishlist, setWishlist] = useState([]);

  useEffect(() => {
    // Retrieve wishlist from local storage or server if logged in
    const savedWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
    setWishlist(savedWishlist);
  }, []);

  const formatPrice = (price) => {
    return `$${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  };

  return (
    <div className="wishlist-container">
      <h1>{user ? `${user.username}'s` : 'Your'} Wishlist</h1>
      <div className="wishlist-grid">
        {wishlist.length === 0 ? (
          <p>Your wishlist is empty.</p>
        ) : (
          wishlist.map((listingId) => {
            const listing = listings.find((item) => item.id === listingId);
            return listing ? (
              <div key={listing.id} className="wishlist-card">
                <ImageSlider images={listing.galleryImages} />
                <div className="wishlist-details">
                  <h3 className="street-address">{listing.streetAddress}</h3>
                  <p className="community">
                    {listing.community} <span className="separator-dot">•</span> {listing.city}
                  </p>
                  <div className="listing-price">{formatPrice(listing.price.replace(/[^0-9]/g, ''))}</div>
                </div>
                <FaHeart className="wishlist-icon active" />
              </div>
            ) : null;
          })
        )}
      </div>
    </div>
  );
};

export default Wishlist;
