import React, { useEffect } from 'react';
import './CommissionExplained.css';
import bgImage from '../assets/images/office.webp'; // Ensure you have a background image
import Plotly from 'plotly.js-basic-dist';
import Footer from './Footer'; // Import the footer component
import SEO from './SEO'; // Correct path for SEO component
import { Link } from 'react-router-dom';
import ListingWidget from './ListingWidget'; // Ensure this path is correct
import thestarLogo from '../assets/images/thestar-logo-2.png';
import CityWidget from './CityWidget';

const image1 = require('../assets/icons/1.webp');
const image2 = require('../assets/icons/2.webp');
const ms = require('../assets/images/ms.webp');
const lastImg1 = require('../assets/images/last-img1.webp');
const lastImg2 = require('../assets/images/last-img2.webp');
const lastImg3 = require('../assets/images/last-img3.webp');
const lastImg4 = require('../assets/images/last-img4.webp');
const lastImg5 = require('../assets/images/last-img5.webp');

const CommissionExplained = () => {
  const home_prices = [600000, 700000, 800000, 900000, 1000000];
  const flat_fee_commissions = [21000, 23500, 26000, 28500, 31000];
  const modern_commissions = [18000, 21000, 24000, 27000, 30000];

  useEffect(() => {
    var allYears = Array.from({ length: 34 }, (x, i) => 1990 + i);
    var allOriginalPrices = [
      255020, 234313, 214971, 206490, 205448, 200220, 197760, 210695, 216017,
      228372, 243255, 251508, 275231, 293067, 315231, 335907, 351941, 376236,
      379080, 395234, 431262, 464989, 497073, 522951, 566611, 622116, 729824,
      822510, 787842, 819153, 929636, 1095475, 1190749, 1126568,
    ];

    var startIndex = allYears.findIndex((year) => year >= 2000);
    var years = allYears.slice(startIndex);
    var originalPrices = allOriginalPrices.slice(startIndex);

    var twoPercentData = originalPrices.map((price) => price * 0.01);
    var fivePercentData = originalPrices.map((price) => price * 0.025);
    var savingsData = fivePercentData.map(
      (value, index) => value - twoPercentData[index]
    );

    var trace2 = {
      x: years,
      y: twoPercentData,
      type: 'bar',
      name: 'Modern Solution 1% Commission',
      marker: {
        color: '#66c4db',
      },
      hovertemplate:
        '2.5% Commission (Outdated Commission Model): $%{customdata[1]:,.2f}<br>' +
        'Modern Solution 1% Commission: $%{y:,.2f}<br>' +
        '<br><b>On Average, Modern Solution Clients Saved: $%{customdata[0]:,.2f}</b><extra></extra>',
      customdata: savingsData.map((save, index) => [
        save,
        fivePercentData[index],
      ]),
    };

    var trace3 = {
      x: years,
      y: fivePercentData,
      type: 'bar',
      name: '2.5% Commission (Outdated Commission Model)',
      marker: {
        color: '#252525',
      },
      hoverinfo: 'skip',
    };

    var layout = {
      barmode: 'overlay',
      xaxis: {
        title: 'Year',
        titlefont: {
          family: 'Inter, sans-serif',
          size: 18,
          color: 'black',
          weight: 'bold',
        },
        tickfont: {
          size: 16,
        },
      },
      yaxis: {
        title: 'Commission Amount',
        automargin: true,
        titlefont: {
          family: 'Inter, sans-serif',
          size: 18,
          color: 'black',
          weight: 'bold',
        },
        tickfont: {
          size: 16,
        },
      },
      legend: {
        orientation: 'h',
        x: 0.5,
        y: 1.1,
        xanchor: 'center',
        yanchor: 'bottom',
        font: {
          size: 16,
        },
      },
      margin: {
        r: 10,
      },
      dragmode: false,
      scrollZoom: false,
      zoom: false,
      pan: false,
      autosize: true,
    };

    Plotly.newPlot('myCommissionChart', [trace3, trace2], layout, {
      responsive: true,
      toImageButtonOptions: {
        format: 'png',
        filename: 'custom_image',
        scale: 1,
      },
      modeBarButtonsToRemove: [
        'zoom2d',
        'pan2d',
        'select2d',
        'lasso2d',
        'zoomIn2d',
        'zoomOut2d',
        'autoScale2d',
        'resetScale2d',
      ],
    });

    var averageHomePriceTrace = {
      x: years,
      y: originalPrices,
      type: 'scatter',
      mode: 'lines+markers',
      name: 'Average Home Price',
      line: {
        color: '#252525',
      },
      marker: {
        color: '#252525',
        size: 8,
      },
      hovertemplate:
        'Year: %{x}<br>' + 'Average Home Price: $%{y:,.2f}<extra></extra>',
    };

    var layout2 = {
      xaxis: {
        title: 'Year',
        titlefont: {
          family: 'Inter, sans-serif',
          size: 16,
          color: 'black',
          weight: 'bold',
        },
        tickfont: {
          size: 14,
        },
      },
      yaxis: {
        title: 'Average Home Price ($)',
        titlefont: {
          family: 'Inter, sans-serif',
          size: 16,
          color: 'black',
          weight: 'bold',
        },
        tickfont: {
          size: 14,
        },
        tickformat: '$,', // This will format the ticks as currency
        separatethousands: true, // Ensures thousands are separated with commas
      },
      legend: {
        orientation: 'h',
        x: 0.5,
        y: -0.2,
        xanchor: 'center',
        yanchor: 'top',
      },
      margin: {
        t: 40,
        b: 80,
        r: 0,
        l: 120,
      },
      // Disable zoom and pan
      dragmode: false,
      scrollZoom: false,
      zoom: false,
      pan: false,
    };

    Plotly.newPlot('homePriceChart', [averageHomePriceTrace], layout2, {
      responsive: true,
    });
  }, []);

  return (
    <div className='commission-explained-container'>
      <SEO
        title="Low Commission Real Estate Services | 1 Percent List Realty | $5000 Cashback"
        description="Welcome to Modern Solution Realty. We offer a range of low commission real estate services. List with 1 Percent Realty and get $5000 cashback when you buy. Call today!"
        keywords={[
          'low commission real estate',
          '1 Percent List Realty',
          'real estate commission explained',
          '$5000 cashback',
          'Modern Solution Realty'
        ]}
        ogImage="https://modernsolution.ca/commission-explained-image.jpg"
        url="https://modernsolution.ca/commission-explained"
      />
      
      <div
        className='hero-section-explained'>
      <div
        className='hero-section-commission'
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <h1>Commission Explained</h1>

        <p>Modern Solution vs. Traditional Real Estate and Flat Fee</p>
        <div className='read-more-button-container'>
          <button className='read-more-button'>
            <a
              href='#first-section'
              style={{ color: 'black', textDecoration: 'none' }}
            >
              Read More
            </a>
          </button>
        </div>
      </div>
      </div>
      
      <div id='first-section' className='content-section'>
      <div id='first-section' className='content-section-inner-content'>
      <div className='commission-top-container'>
        <h2>
          How a 1% Listing Commission Can
          Save You Thousands When Selling Your Home
        </h2>

        <p>
          When selling a house in Ontario, there are typically two agents
          involved: the <strong>listing agent (seller’s agent)</strong> and the{' '}
          <strong>buyer’s agent</strong>. Both agents are compensated by the
          seller.
        </p>
        
        <div className='boxes-section'>
          <div className='box'>
            <h3>The Listing Agent</h3>
            <img src={image1} alt='Listing Agent' />
            <p>The agent responsible for selling the home</p>

            <p>MSR Fee: 1%</p>
            <p>Traditional Fee: 2.5%</p>
            <p>Flat Fee: $6,000</p>
          </div>
          <div className='box'>
            <h3>The Buying Agent</h3>
            <img src={image2} alt='Buying Agent' />
            <p>Represents the buyers of the home</p>

            <p>MSR Fee: 1-2%</p>
            <p>Traditional Fee: 2.5%</p>
            <p>Flat Fee: 2.5%</p>
          </div>
        </div>
        <div className='boxes-information'>
        <p>
          At Modern Solution Realty, we offer an unbeatable 1% commission to
          sell your property, far lower than the excessive 2.5% rates elsewhere.
          Flat fees of $4,000 – $6,000 also fall short, failing to meet the
          unique needs of different properties and lacking incentives for
          realtors to work diligently.
        </p>
        <p>
          Our 1% commission model guarantees full, tailored service without
          compromises. The commitment to excellence has driven over 2,100
          successful transactions since 2014. Trust Modern Solution Realty for
          exceptional value and dedication in every sale.
        </p>
        <Link to='/contact'>
          <button className='talk-button'>Let's Talk</button>
        </Link>
        </div>
        {/* New Section */}
        <div className='modern-affordable-section'>
          <h2>
            Modern Affordable Commission
          </h2>
          <p>Why Pay More for the Same Service?</p>

        </div>
        {/* Pricing Table */}
        <div className='pricing-table'>
          <table>
            <thead>
              <tr>
                <th>Home Sale Price</th>
                <th style={{ maxWidth: '250px', backgroundColor: '#e9cccc' }}>
                  5% Commission Maximum (Outdated Model)
                </th>
                <th
                  style={{
                    backgroundColor: '#d3d3d3',
                    color: '#1a1a1a',
                    fontWeight: 'bold',
                    padding: '2vw',
                    marginBottom: '1vw',
                  }}
                >
                  $6,000 Flat
                  <br /> + 2.5%
                </th>
                <th
                  style={{
                    maxWidth: '250px',
                    backgroundColor: '#66c4db',
                    color: '#1a1a1a',
                    fontWeight: 'bold',
                    padding: '2vw',
                    marginBottom: '1vw',
                  }}
                >
                  <img src={ms} alt='MSR Logo' className='msr-logo' />
                  <br />
                  Modern 1% + 2%
                </th>
              </tr>
            </thead>
            <tbody>
              {home_prices.map((price, index) => (
                <tr key={index}>
                  <td>${price.toLocaleString()}</td>
                  <td className='strike-through'>
                    ${(price * 0.05).toLocaleString()}
                  </td>
                  <td>${flat_fee_commissions[index].toLocaleString()}</td>
                  <td>${modern_commissions[index].toLocaleString()}</td>
                </tr>
              ))}
              <tr>
                <td>$500,000</td>
                <td>$25,000</td>
                <td>$18,500</td>
                <td>$15,000</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>On Average, our clients saved $18,000</p>
        <Link to='/contact'>
          <button className='valuation-button'>Free Home Valuation</button>
        </Link>
        
        {/* Section Divider and Heading */}
        <div className='section-divider'></div>
        
      </div>
      </div>
      {/* Featured Listings */}
        <h2>This is how it normally works:</h2>

        {/* Updated Content Sections with Alternating Images and Text */}
        {/* Section 1 - Text Left, Image Right */}
        <div className="content-image-section animate-on-scroll">
          <div className="text-content">
            <h3>This couple is selling their house</h3>
            <p>
              This couple is selling their home in the GTA and will need to hire
              a Listing Agent to help them with everything involved.
            </p>
            <p>
              They have an idea as to what it might be worth, but don’t know
              much about how everything works and just wants a smooth
              transition.
            </p>
          </div>
          <div className="image-content">
            <img src={lastImg1} alt="Selling Couple" className="image-placeholder" />
          </div>
        </div>

        {/* Section 2 - Text Right, Image Left */}
        <div className="content-image-section text-right animate-on-scroll">
          <div className="image-content">
            <img src={lastImg2} alt="House Being Sold" className="image-placeholder" />
          </div>
          <div className="text-content">
            <h3>This is the house being sold</h3>
            <p>
              Whichever agent ends up listing it will appraise its actual value
              by assessing it against similar neighborhood sales and will set a
              market price.
            </p>
            <p>
              The selling price is always adjustable and can be raised or
              lowered.
            </p>
          </div>
        </div>

        {/* Section 3 - Text Left, Image Right */}
        <div className="content-image-section animate-on-scroll">
          <div className="text-content">
            <h3>This is the Listing Agent</h3>
            <p>
              Listing agents represent the home sellers and take care of legal
              things, listings, home showings and negotiate to sell the home for
              the highest price possible. In Southern Ontario, Listings Agents
              traditionally receive 2.5% of a home’s final sale price for their
              services.
            </p>
          </div>
          <div className="image-content">
            <img src={lastImg3} alt="Listing Agent" className="image-placeholder" />
          </div>
        </div>

        {/* Section 4 - Text Right, Image Left */}
        <div className="content-image-section text-right animate-on-scroll">
          <div className="image-content">
            <img src={lastImg4} alt="Buying Couple" className="image-placeholder" />
          </div>
          <div className="text-content">
            <h3>This is the couple buying the house</h3>
            <p>
              This couple wants to buy a house in the GTA and have hired a
              “Buying Agent” to help them with everything involved.
            </p>
            <p>Most agents can perform both buying and selling roles.</p>
          </div>
        </div>

        {/* Section 5 - Text Left, Image Right */}
        <div className="content-image-section animate-on-scroll">
          <div className="text-content">
            <h3>This is their Buying Agent</h3>
            <p>
              “Buying” agents represent the home buyers and take care of legal
              things, finding homes to view and negotiate to buy the home for
              the lowest possible price.
            </p>
            <p>
              In southern Ontario, “Buying” agents also traditionally receive
              2% to 2.5% of the home’s final sale price.
            </p>
          </div>
          <div className="image-content">
            <img src={lastImg5} alt="Buying Agent" className="image-placeholder" />
          </div>
        </div>
        
      <div className='full-width-section'>
        <h2>
          Modern Solution Realty vs Other
          Realty Brokerages
        </h2>
        <div className='charts-container'>
          <div id='map-container'>
            <div id='overlay'>
              <div className='overlay-content'>
                <div className='overlay-text'>
                  Interactive Chart - Hover/Click to see data
                </div>
              </div>
            </div>
            <div id='myCommissionChart'></div>
          </div>
          <div id='homePriceChart'></div>
        </div>
      </div>
      {/* New Section Below Graphs */}
      <div className='text-section-bottom'>
      <div className='text-section'>
        <p>
          Real estate commissions have grown a whopping 440% in the last 33
          years. While other professions (nurses, teachers, etc.) have had their
          salaries only increase by 15%. This is why Modern Solution believes in
          a listing commission of 1% to the listing side.
        </p>
      </div>
      </div>
      </div>
      {/* Featured Listings */}
      <h2 className="featured-listings-heading">Modern Solution Listings</h2>
      <ListingWidget />

      

      {/* City Links Section */}
      <div className="city-links-section">
        <CityWidget />
      </div>

   {/* Toronto Star Section */}
<div className="toronto-star-wrapper" style={{ display: 'flex', justifyContent: 'center' }}>
  <div className="toronto-star-container" style={{ maxWidth: '1500px', display: 'flex', gap: '1rem', alignItems: 'center' }}>
    <div className="home-page-toronto-star" style={{ flex: '1' }}>
      <img src={thestarLogo} alt="The Star Logo" className="thestar-logo" />
      <h2 className="toronto-star-heading">Modern Solution Featured in Toronto Star</h2>
      <p className="toronto-star-paragraph">
        In a real estate world where outdated commission rates have long been the norm,
        Modern Solution Realty Inc. is proud to offer a refreshing and cost-effective
        alternative.
        
        Traditional commission structures, often hovering around five to six per cent, have
        burdened home sellers with exorbitant fees. For instance, selling a $1,000,000 home with
        a standard five per cent commission could cost a staggering $50,000 — an expense that
        seems increasingly unreasonable in today’s dynamic market.
      </p>
      <a
        href="https://www.thestar.com/sponsored-sections/a-modern-solution-approach-to-real-estate-with-one-per-cent-commission/article_1ca3e32e-6eec-11ef-84ea-53f224f162c8.html"
        target="_blank"
        rel="noopener noreferrer"
        className="read-more-link"
      >
        Read More
      </a>
    </div>

    {/* New Photo Column */}
    <div className="toronto-star-image-column" style={{ flex: '0.4', paddingLeft: '1rem' }}>
      <img src={require('../assets/images/thestar-office.webp')} alt="The Star Office" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
    </div>
  </div>
</div>
      <Footer /> 
    </div>
  );
};

export default CommissionExplained;
