import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './GoogleReviews.css';
import { Navigation, Autoplay } from 'swiper/modules';
import { FaStar } from 'react-icons/fa';
import googleLogo from '../assets/images/google-reviews.webp';

const GoogleReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [overallRating, setOverallRating] = useState(0);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch('/reviews-new.json'); // Fetch from local JSON file
        const data = await response.json();

        // Filter out reviews that have no text
        const filteredReviews = data.result.reviews.filter(
          (review) => review.text && review.text.trim() !== ''
        );

        setReviews(filteredReviews);
        setOverallRating(data.result.overall_rating); // Set overall rating
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchReviews();
  }, []);

  const [expandedReviewIndex, setExpandedReviewIndex] = useState(null);
  const [activeIndex, setActiveIndex] = useState(1); // Start on the second slide
  const [autoplay, setAutoplay] = useState(true); // Autoplay state

  const handleReadMoreClick = (index) => {
    setExpandedReviewIndex(index === expandedReviewIndex ? null : index);
  };

  const settings = {
    initialSlide: 1, // Start on the second slide
    spaceBetween: 15,
    slidesPerView: 3,
    centeredSlides: true,
    loop: true, // Enable infinite loop
    navigation: true,
    autoplay: autoplay
      ? {
          delay: 3000,
          disableOnInteraction: true,
        }
      : false,
    modules: [Navigation, Autoplay],
    onSlideChange: (swiper) => {
      setActiveIndex(swiper.realIndex); // Use realIndex for looped slides
    },
    onSwiper: (swiper) => {
      setActiveIndex(swiper.realIndex); // Use realIndex for looped slides
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      600: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
  };

  return (
    <div className='google-reviews'>
      <div className='overall-rating'>
        <div className='stars'>
          {[...Array(5)].map((_, i) => (
            <FaStar
              key={i}
              className='star'
              style={{ fontSize: '3rem' }}
              color={i < overallRating ? '#9cdcf8' : '#e4e5e9'}
            />
          ))}
        </div>
        <p className='review-count'>Based on 222 reviews</p> {/* Hardcoded review count */}
        <img src={googleLogo} alt='Google Logo' className='google-logo' />
      </div>
      <div className='slider-container'> {/* New container for the slider */}
        <Swiper {...settings}>
          {reviews.map((review, index) => {
            const isExpanded = index === expandedReviewIndex;
  
            const reviewText =
              review.text && review.text.trim() !== ''
                ? isExpanded
                  ? review.text
                  : review.text.slice(0, 100) +
                    (review.text.length > 100 ? '...' : '')
                : '';
            let slideClass = 'side-slide';
  
            if (index === activeIndex) {
              slideClass = 'center-slide';
            }
  
            return (
              <SwiperSlide key={index} className={slideClass}>
                <div className='review-card' onClick={() => setAutoplay(false)}>
                  {' '}
                  {/* Disable autoplay on click */}
                  <div className='star-rating'>
                    {[...Array(5)].map((_, i) => (
                      <FaStar key={i} className='star' />
                    ))}
                  </div>
                  <p className='review-text'>
                    {reviewText === '' ? '' : `"${reviewText}"`}
                  </p>
                  <p className='review-author'>- {review.author_name}</p>
                  {review.text.length > 100 && (
                    <button
                      className='reviews-read-more-button'
                      onClick={() => handleReadMoreClick(index)}
                    >
                      {isExpanded ? 'Read Less' : 'Read More'}
                    </button>
                  )}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default GoogleReviews;
