import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './AddArticle.css';

// Registering Image Blot to insert images
const Image = Quill.import('formats/image');
Image.className = 'article-image';
Quill.register(Image, true);

const AddArticle = () => {
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [articles, setArticles] = useState([]); // You may need to load these from a static JSON list
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);
  const quillRef = useRef(null);

  useEffect(() => {
    // You can fetch articles from a local JSON file or a mock API for development
    // Assume articles is preloaded for now
    if (id) {
      // Fetch the specific article for editing (mocked here)
      const article = articles.find(article => article.id === id);
      if (article) {
        setTitle(article.title);
        setContent(article.content);
        setCategory(article.category);
        setIsEditMode(true);
      }
    }
  }, [id, articles]);

  const handleImageUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        const imageUrl = reader.result;
        const editor = quillRef.current.getEditor();
        const range = editor.getSelection();
        editor.insertEmbed(range.index, 'image', imageUrl);
      };

      reader.readAsDataURL(file);
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const articleData = {
      title,
      content,
      date: new Date().toISOString().split('T')[0],
      category,
      slug: title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''),
    };

    // Create a downloadable JSON file for the article
    const blob = new Blob([JSON.stringify(articleData, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${articleData.slug}.json`;
    a.click();

    navigate('/articles');
  };

  const modules = {
    toolbar: {
      container: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'align': [] }],
        ['link', 'image', 'video'],
        ['clean'],
        [{ 'color': [] }, { 'background': [] }],
      ],
      handlers: {
        image: handleImageUpload,
      },
    },
  };

  return (
    <div className="articles-container">
      <div className="sidebar">
        <h3>{isEditMode ? 'Edit Article' : 'Add New Article'}</h3>
        <ul className="article-list">
          {articles.map((article) => (
            <li key={article.id} className="sidebar-item" onClick={() => navigate(`/add-article/${article.id}`)}>
              {article.title}
            </li>
          ))}
        </ul>
      </div>
      <div className="content">
        <form onSubmit={handleSubmit}>
          <div>
            <label className="input-label">Title</label>
            <input 
              type="text" 
              value={title} 
              onChange={(e) => setTitle(e.target.value)} 
              required 
              className="input-field"
            />
          </div>
          <div>
            <label className="input-label">Category</label>
            <input 
              type="text" 
              value={category} 
              onChange={(e) => setCategory(e.target.value)} 
              required 
              className="input-field"
            />
          </div>
          <div>
            <label className="input-label">Content</label>
            <ReactQuill 
              ref={quillRef} 
              value={content} 
              onChange={setContent} 
              className="editor" 
              style={{ height: '500px' }} 
              modules={modules}
            />
          </div>
          <button type="submit" className="submit-button">
            {isEditMode ? 'Update Article' : 'Post Article'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddArticle;
