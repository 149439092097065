import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import '../Auth.css';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const { register } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add('register-page-body');
    return () => {
      document.body.classList.remove('register-page-body');
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    const role = 'user'; // Set role automatically to 'user'
    try {
      const response = await register(username, email, password, role); // Pass role to the register function
      setMessage(response.data.message);
      const redirectTo = new URLSearchParams(location.search).get('redirect') || '/';
      // navigate(redirectTo); // Redirect back to the listing or home if not provided
    } catch (error) {
      console.error(error);
      setError(error.message || 'Registration failed. Please try again.');
    }
  };

  return (
    <div className="register-page">
      <div className="register-container">
        <h1>Register</h1>
        <h3>In order to view sold listings, our visitors must make an account</h3>
        {!message && (<form onSubmit={handleSubmit}>
          {error && <p className="error">{error}</p>}
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            required
          />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <button type="submit">Register</button>
        </form>)}
        {message && (
            <div>
              <p className="success">{message}</p>
              <p>Please check your email to activate account.</p>
            </div>
        )}
        <p>
          Already have an account? <Link to={`/login?redirect=${new URLSearchParams(location.search).get('redirect') || '/'}`}>Login here</Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
