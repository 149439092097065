import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from '../services/axios';
import './Listings.css';
import AuthContext from '../context/AuthContext';
import { ReactComponent as BedroomIcon } from '../assets/icons/bedroom-3.svg';
import { ReactComponent as BathroomIcon } from '../assets/icons/bathroom.svg';
import { ReactComponent as SquareFeetIcon } from '../assets/icons/squarefeet.svg';
import ListingDetail from './ListingDetail';
import { FaShareAlt } from 'react-icons/fa';
import { FaSearch } from 'react-icons/fa';
import SharePopup from './SharePopup';  // Import the new SharePopup component
import './SharePopup.css';
// src/components/Listings.js
import { initialCities } from './cities';
import { neighborhoods } from './neighborhoods';


const Listings = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [priceRange, setPriceRange] = useState({ min: 0, max: 10000000 });
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const toggleMobileFilters = () => {
    setShowMobileFilters(!showMobileFilters);
  };
  const [selectedNeighborhood, setSelectedNeighborhood] = useState('All');
  
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedStyles, setSelectedStyles] = useState([]);
  

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleStyleChange = (style) => {
    setSelectedStyles((prevStyles) =>
      prevStyles.includes(style) ? prevStyles.filter((s) => s !== style) : [...prevStyles, style]
    );
  };

  
  const [selectedPropertySearch, setSelectedPropertySearch] = useState([]);



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        buttonRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const [selectedCity, setSelectedCity] = useState('All');
  const [propertyStyle, setPropertyStyle] = useState('All');

  const [selectedBedrooms, setSelectedBedrooms] = useState('Any');
  const [selectedBathrooms, setSelectedBathrooms] = useState('Any');
  const [searchQuery, setSearchQuery] = useState('');
  const [hoveredListing, setHoveredListing] = useState(null);
  const [selectedListing, setSelectedListing] = useState(null);
 
  const [filteredListings, setFilteredListings] = useState([]);
  const [error, setError] = useState(null);
  const searchTimeoutRef = useRef(null);  // Use ref to store timeout ID

  const [loading, setLoading] = useState(true);
  const [filteredCities, setFilteredCities] = useState([]);

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [totalListings, setTotalListings] = useState(0);
  

  const [selectedStyle, setSelectedStyle] = useState('All');
const [styles, setStyles] = useState(['All']); // Initialize with 'All'
  
  const [initialBedrooms] = useState([...Array(9).keys()].map((num) => (num + 1).toString()));
  const [initialBathrooms] = useState([...Array(10).keys()].map((num) => (num + 1).toString()));
  const [initialZoomLevel] = useState(8);
  const mapInstance = useRef(null);
  const listingsPerPage =  20;
  const maxTotalListings = 70000;
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null); // Ref for the dropdown button
 

  const { user } = useContext(AuthContext);

  // Property Styles for Dropdown Menu "Property Styles"

  const PROPERTY_STYLES = [
    'All',
    '1 1/2 Storey',
    '2 1/2 Storey',
    '2-Storey',
    '3-Storey',
    'Apartment',
    'Bachelor/Studio',
    'Backsplit 3',
    'Backsplit 4',
    'Backsplit 5',
    'Bungalow',
    'Bungalow-Raised',
    'Bungaloft',
    'Loft',
    'Multi-Level',
    'Other',
    'Sidesplit 3',
    'Sidesplit 4',
    'Stacked Townhse'
  ];

   // Property Styles for Dropdown Menu "Property Styles"

  const handleCitySelection = (selection) => {
    const selectedItem = selection.label.split(',')[0]; // Extract the neighborhood/city name
  
    if (selection.type === 'City') {
      setSearchQuery(''); // Clear search input
      setSelectedCity(selectedItem); // Apply city filter
      setSelectedNeighborhood(''); // Clear neighborhood when city is selected
    } else if (selection.type === 'Neighborhood') {
      setSearchQuery(''); // Clear search input
      setSelectedCity(''); // Clear city field since it's a neighborhood search
      setSelectedNeighborhood(selectedItem); // Apply neighborhood filter
    } else if (selection.type === 'Address' && selection.listingId) {
      // Redirect to the listing page for that particular address
      const addressSlug = selectedItem.replace(/ /g, '-').replace(/[^a-zA-Z0-9\-]/g, ''); // Clean up address for the URL
      const listingUrl = `/listing/${selection.listingId}/${addressSlug}`;
      window.location.href = listingUrl; // Redirect to the listing URL
    }
  
    setFilteredCities([]); // Clear dropdown
  };
  

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFilteredCities([]); 
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  useEffect(() => {
    // Parse query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);

    // Set state based on URL parameters
    const city = queryParams.get('city') || 'All';
    const minPrice = parseInt(queryParams.get('minPrice'), 10) || 0;
    const maxPrice = parseInt(queryParams.get('maxPrice'), 10) || 10000000;
    const bedrooms = queryParams.get('bedrooms') || 'Any';
    const bathrooms = queryParams.get('bathrooms') || 'Any';
    const search = queryParams.get('search') || '';
    const page = parseInt(queryParams.get('page'), 10) || 1;

    setSelectedCity(city);
    setPriceRange({ min: minPrice, max: maxPrice });
    setSelectedBedrooms(bedrooms);
    setSelectedBathrooms(bathrooms);
    setSearchQuery(search);
    setCurrentPage(page);
  }, []);


  

  const [showSharePopup, setShowSharePopup] = useState(false);
  const [currentListingUrl, setCurrentListingUrl] = useState('');

  const handleShare = (e) => {
    e.preventDefault();
    
    const addressSlug = selectedListing.address
      .replace(/ /g, '-')
      .replace(/[^a-zA-Z0-9\-]/g, '');
    const unitNum = selectedListing.unit_num ? `-${selectedListing.unit_num}` : '';
    const link = `${window.location.origin}/listing/${selectedListing.ml_num}/${addressSlug}${unitNum}`;
    
    setCurrentListingUrl(link);

    if (window.innerWidth > 768) {  // Desktop
      setShowSharePopup(true);
    } else {  // Mobile
      if (navigator.share) {
        navigator.share({
          title: 'Check out this listing',
          url: link
        }).catch(console.error);
      } else {
        // Fallback for mobile browsers that don't support Web Share API
        setShowSharePopup(true);
      }
    }
  };
  
  
  
  useEffect(() => {
    if (filteredListings.length > 0) {
      const uniqueStyles = ['All', ...new Set(filteredListings
        .map(listing => listing.style)
        .filter(style => style && style.trim() !== ''))];
      setStyles(uniqueStyles);
    }
  }, [filteredListings]);

  useEffect(() => {
    // Initial load to fetch total listings count and set pagination
    fetchClusterListings().then(() => {
      fetchPageListings(); // Fetch initial page listings after getting the total count
    });
  }, []); // Empty dependency array to run only on initial load
  
  const fetchPageListings = async (searchQueryParam = searchQuery) => {
    setLoading(true);
    
    try {
      const pageOffset = (currentPage - 1) * listingsPerPage;
      
      // Map "House" to "Residential" in selectedPropertySearch
      const mappedPropertySearch = selectedPropertySearch.map(style =>
        style === "House" ? "Residential" : style
      );
  
      const pageResponse = await axios.get('/listings', {
        params: {
          limit: listingsPerPage,
          offset: pageOffset,
          city: selectedCity !== 'All' ? selectedCity : null,
          community: selectedNeighborhood !== 'All' && selectedNeighborhood !== '' ? selectedNeighborhood : null,
          bedrooms: selectedBedrooms !== 'Any' ? selectedBedrooms : null,
          bathrooms: selectedBathrooms !== 'Any' ? selectedBathrooms : null,
          propertySearch: mappedPropertySearch.length > 0 ? mappedPropertySearch : null,
          search: searchQueryParam || null,
          minPrice: priceRange.min,
          maxPrice: priceRange.max,
        },
      });
  
      setFilteredListings(pageResponse.data);
    } catch (error) {
      console.error('Error fetching page listings:', error);
    } finally {
      setLoading(false);
    }
  };
  
  
  
  
  


  
  useEffect(() => {
    // After listings are loaded, update currentPage based on query params if necessary
    const queryParams = new URLSearchParams(window.location.search);
    const pageFromURL = parseInt(queryParams.get('page'), 10);
    if (pageFromURL && pageFromURL !== currentPage) {
      setCurrentPage(pageFromURL); // Correct the page after data is loaded
    }
  }, [loading]);

 // Update the fetchClusterListings effect to include style

 const fetchClusterListings = async (searchQueryParam = searchQuery) => {
  try {
    // Map "House" to "Residential" for SQL compatibility
    const mappedPropertySearch = selectedPropertySearch.map(style =>
      style === "House" ? "Residential" : style
    );

    const totalResponse = await axios.get('/listings/count', {
      params: {
        city: selectedCity !== 'All' ? selectedCity : null,
        community: selectedNeighborhood !== 'All' && selectedNeighborhood !== '' ? selectedNeighborhood : null,
        bedrooms: selectedBedrooms !== 'Any' ? selectedBedrooms : null,
        bathrooms: selectedBathrooms !== 'Any' ? selectedBathrooms : null,
        propertySearch: mappedPropertySearch.length > 0 ? mappedPropertySearch : null,
        search: searchQueryParam || null,
        minPrice: priceRange.min,
        maxPrice: priceRange.max,
      },
    });

    const totalCount = Math.min(totalResponse.data.totalCount, maxTotalListings);
    setTotalListings(totalCount);
  } catch (error) {
    console.error('Error fetching total listings count:', error);
  }
};

  

  useEffect(() => {
    setCurrentPage(1);
    setFilteredListings([]);

    if (mapInstance.current) {
      mapInstance.current.setZoom(initialZoomLevel);
    }
  }, [selectedCity, selectedBedrooms, selectedBathrooms, searchQuery, priceRange]);
  
  const formatPrice = (price) => {
    return `$${Number(price).toLocaleString()}`;
  };


  const handleFilterChange = (setter, filterType) => (value) => {
    setter(value);
    setCurrentPage(1); // Reset to page 1 when any filter changes
  
    const queryParams = new URLSearchParams({
      city: filterType === 'city' ? value : selectedCity,
      minPrice: priceRange.min,
      maxPrice: priceRange.max,
      bedrooms: filterType === 'bedrooms' ? value : selectedBedrooms,
      bathrooms: filterType === 'bathrooms' ? value : selectedBathrooms,
      style: filterType === 'style' ? value : selectedStyle,
      propertySearch: filterType === 'propertySearch' ? value : selectedPropertySearch.join(','), // Include propertySearch in URL
      search: searchQuery,
      page: 1,
    }).toString();
  
    window.history.pushState(null, '', `/listings?${queryParams}`);
  };
  
  
  
  
  // Debounced API fetch logic in useEffect
  useEffect(() => {
    const fetchListingsData = async () => {
      setLoading(true);
      await fetchClusterListings(searchQuery);
      await fetchPageListings(searchQuery);
      setLoading(false);
    };
  
    // Use a timeout to debounce the API call, only triggering it after user stops changing filters
    const delayDebounceFn = setTimeout(() => {
      fetchListingsData();
    }, 300); // 300ms debounce
  
    return () => clearTimeout(delayDebounceFn); // Clear debounce on filter change
  }, [selectedCity, selectedBedrooms, selectedBathrooms, selectedStyle, selectedPropertySearch, searchQuery, priceRange]); // Add propertySearch to dependencies
  
  
  
  

  const totalPages = Math.ceil(totalListings / listingsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  
    // Update the URL to reflect the new page
    const queryParams = new URLSearchParams({
      city: selectedCity,
      minPrice: priceRange.min,
      maxPrice: priceRange.max,
      bedrooms: selectedBedrooms,
      bathrooms: selectedBathrooms,
      search: searchQuery,
      page: pageNumber  // Update the URL with the new page number
    }).toString();
  
    // Push the updated query string to the URL (without reloading the page)
    window.history.pushState(null, '', `/listings?${queryParams}`);
  };
  

  const getPageNumbers = () => {
    const maxPages = 7;
    let startPage = Math.max(1, currentPage - Math.floor(maxPages / 2));
    let endPage = startPage + maxPages - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPages + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  const pageNumbers = getPageNumbers();

  const [uniqueCities] = useState(initialCities);

  // Add style to your clearFilters function
  const clearFilters = () => {
    setPriceRange({ min: 0, max: 10000000 });
    setSelectedCity('All');
    setSelectedBedrooms('Any');
    setSelectedBathrooms('Any');
    setSelectedStyle('All');
    setSearchQuery('');
    setManualSearchQuery('');
    setSearchApplied(false);
    setSelectedNeighborhood('');
    setSelectedPropertySearch([]); // Clear property search filter
    setCurrentPage(1);
  
    // Update the URL to reflect the cleared filters
    const queryParams = new URLSearchParams({
      city: 'All',
      minPrice: 0,
      maxPrice: 10000000,
      bedrooms: 'Any',
      bathrooms: 'Any',
      style: 'All',
      propertySearch: '', // Clear property search in the URL as well
      search: '',
      page: 1
    }).toString();
    window.history.pushState(null, '', `/listings?${queryParams}`);
  
    // Fetch updated listings after clearing filters
    fetchPageListings();
  };
  

const removeFilter = (filterType) => {
  if (filterType === 'city') {
    setSelectedCity('All');
  } else if (filterType === 'style') {
    setSelectedStyle('All');
  } else if (filterType === 'propertySearch') {  // Reset property search filter
    setSelectedPropertySearch([]);
  } else if (filterType === 'price') {
    setPriceRange({ min: 0, max: 10000000 });
  } else if (filterType === 'bedrooms') {
    setSelectedBedrooms('Any');
  } else if (filterType === 'bathrooms') {
    setSelectedBathrooms('Any');
  }
  setCurrentPage(1);
  setFilteredListings([]);
};



  const handleImageClick = (e, listing) => {
    e.stopPropagation();
    
    const shouldBlur = listing.search_type !== 'IDX' && !user;
  
    if (shouldBlur) {
      // Save current filters and page in the URL
      const queryParams = new URLSearchParams({
        city: selectedCity,
        minPrice: priceRange.min,
        maxPrice: priceRange.max,
        bedrooms: selectedBedrooms,
        bathrooms: selectedBathrooms,
        search: searchQuery,
        page: currentPage
      }).toString();
  
      // Redirect to login with the correct base URL and query parameters
      const loginUrl = `/login?redirect=${encodeURIComponent(`/listings?${queryParams}`)}`;
      
      window.location.href = loginUrl;  // Redirect the user
      return;
    }
  
    setSelectedListing(listing);
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      console.log('Enter key pressed. Search query:', manualSearchQuery); // Log search query
      handleSearch(); // Trigger search
    }
  };
  

  const [manualSearchQuery, setManualSearchQuery] = useState('');
  
  const handleCityInputChange = (e) => {
    const input = e.target.value;
    setManualSearchQuery(input);

    // Clear the previous timeout if the user types again before 1 second
    if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
    }

    // Immediately clear the dropdown
    setFilteredCities([]);  // Clear suggestions to hide dropdown while waiting for the next search results

    searchTimeoutRef.current = setTimeout(() => {
      if (input.length >= 2) {
          // Match Cities
          const matchedCities = initialCities.filter((city) =>
              city.toLowerCase().startsWith(input.toLowerCase())
          );

          // Match Neighborhoods
          const matchedNeighborhoods = neighborhoods.filter((neighborhood) =>
              neighborhood.name.toLowerCase().startsWith(input.toLowerCase())
          );

            // Query matching addresses from the listings
            axios.get('/listings', {
                params: {
                    addressSearch: input,  // Send the input as part of the query
                    limit: 5  // Limit the number of address results to 5
                }
            }).then(response => {
                const matchedAddresses = response.data
                    .filter((listing) =>
                        listing.address.toLowerCase().includes(input.toLowerCase())  // Match anywhere in the address
                    )
                    .slice(0, 5)  // Limit to 5 address results
                    .map(listing => ({
                        label: `${listing.address}${listing.unit_num ? ` #${listing.unit_num}` : ''}, ${listing.municipality}, Ontario`,  // Add city and Ontario
                        type: 'Address',
                        listingId: listing.ml_num,  // Store the MLS number
                    }));

                // Combine suggestions but limit the total to 5
                const suggestions = [
                    ...matchedCities.map((city) => ({
                        label: `${city}, Ontario`,
                        type: 'City',
                    })),
                    ...matchedNeighborhoods.map((neighborhood) => ({
                        label: `${neighborhood.name}, ${neighborhood.city}, Ontario`,
                        type: 'Neighborhood',
                    })),
                    ...matchedAddresses
                ];

                // Limit total combined suggestions to 5
                setFilteredCities(suggestions.slice(0, 5));
            }).catch(error => {
                console.error('Error fetching address suggestions:', error);
            });
        } else {
            setFilteredCities([]);  // Clear suggestions if input is less than 2 characters
        }
    }, 1000);  // Set a 1-second delay
};


  
  // Add a new state to track if the search has been applied
const [searchApplied, setSearchApplied] = useState(false);

// In `handleSearch`, update `searchQuery` and remove direct calls to `fetchPageListings` and `fetchClusterListings`
const handleSearch = () => {
  console.log('Search initiated. Search query:', manualSearchQuery);
  setSearchApplied(true); // Mark search as applied
  setSearchQuery(manualSearchQuery); // Update `searchQuery`, triggering the `useEffect`

  // Update URL without immediate fetches
  const queryParams = new URLSearchParams({
    city: selectedCity,
    community: selectedNeighborhood !== 'All' && selectedNeighborhood !== '' ? selectedNeighborhood : null,
    minPrice: priceRange.min,
    maxPrice: priceRange.max,
    bedrooms: selectedBedrooms,
    bathrooms: selectedBathrooms,
    search: manualSearchQuery, 
    page: 1,
  }).toString();

  window.history.pushState(null, '', `/listings?${queryParams}`);
};

useEffect(() => {
  const fetchListingsData = async () => {
    setLoading(true);
    await fetchClusterListings(searchQuery);
    await fetchPageListings(searchQuery);
    setLoading(false);
  };

  // Debounce API call
  const delayDebounceFn = setTimeout(() => {
    if (searchQuery || currentPage) {  // Add currentPage check
      fetchListingsData();
    }
  }, 300);

  return () => clearTimeout(delayDebounceFn);
}, [selectedCity, selectedBedrooms, selectedBathrooms, selectedStyle, searchQuery, priceRange, currentPage]);  // Add currentPage dependency




  
  const renderImage = (listing) => {
    const mainImage = listing.first_photo_url;
  
    // Determine if we should blur the image based on search_type
    const shouldBlur = listing.search_type !== 'IDX' && !user;
  
    return (
      <div className='image-container'>
        <img
          src={mainImage}
          alt="Listing"
          className={`listing-image ${shouldBlur ? 'blurred' : ''}`}
          onClick={(e) => {
            if (!shouldBlur) {
              handleImageClick(e, listing);
            }
          }}
        />
        {shouldBlur && (
          <div className="login-button-overlay">
            <a
              href="#"
              className="login-button"
              onClick={(e) => {
                e.preventDefault();

  
                // Create URLSearchParams for the current filters, page, and MLS number
                const queryParams = new URLSearchParams({
                  city: selectedCity,
                  minPrice: priceRange.min,
                  maxPrice: priceRange.max,
                  bedrooms: selectedBedrooms,
                  bathrooms: selectedBathrooms,
                  search: searchQuery,
                  page: currentPage,
                  ml_num: listing.ml_num  // Append the MLS number correctly
                });
  
                // Convert queryParams to a string for the URL
                const queryString = queryParams.toString();              
  
                // Build the login URL and ensure encoding is correct
                const loginUrl = `/login?redirect=${encodeURIComponent(`/listings?${queryString}`)}`;

  
                // Redirect to login page with the constructed URL
                window.location.href = loginUrl;
              }}
            >
              Login to view details
            </a>
          </div>
        )}
      </div>
    );
  };
  
  
  return (
    <div className='listing-search-container'>
      {error && (
        <div className='error-message'>
          Error fetching listings data: {error}
        </div>
      )}
    
      <div className='filter-bar'>
        <div className='filter-item price-range-filter'>
          <label>Price Range:</label>
          <div className="price-inputs">
            <input
              type="number"
              placeholder="Min"
              value={priceRange.min}
              onChange={(e) => handleFilterChange(setPriceRange)({ ...priceRange, min: e.target.value })}
              className="price-input"
            />
            <span> - </span>
            <input
              type="number"
              placeholder="Max"
              value={priceRange.max}
              onChange={(e) => handleFilterChange(setPriceRange)({ ...priceRange, max: e.target.value })}
              className="price-input"
            />
          </div>
        </div>
        <div className='filter-item'>
          <label>City:</label>
          <select
            value={selectedCity}
            onChange={(e) => handleFilterChange(setSelectedCity)(e.target.value)}
            className='city-select'
          >
            <option value='All'>Select City</option>
            {uniqueCities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>
        <div className='filter-item'>
      <label>Property Style:</label>
      <div className='custom-dropdown'>
        <button
          ref={buttonRef}
          type='button'
          onClick={toggleDropdown}
          className='dropdown-button'
        >
          {selectedStyles.length > 0 ? selectedStyles.join(', ') : 'Select Property Style'}
        </button>
        {isDropdownOpen && (
          <div ref={dropdownRef} className='dropdown-menu'>
            <label className='radio-option'>
              <input
                type='checkbox'
                name='propertyStyle'
                value='Condo'
                checked={selectedStyles.includes('Condo')}
                onChange={() => handleStyleChange('Condo')}
              />
              Condo
            </label>
            <label className='radio-option'>
              <input
                type='checkbox'
                name='propertyStyle'
                value='House'
                checked={selectedStyles.includes('House')}
                onChange={() => handleStyleChange('House')}
              />
              House
            </label>
            <button
  onClick={() => {
    setSelectedPropertySearch(selectedStyles); // Update selectedPropertySearch filter
    setIsDropdownOpen(false); // Close dropdown
    
    // Map "House" to "Residential" and set the URL with updated filters
    const mappedPropertySearch = selectedStyles.map(style =>
      style === "House" ? "Residential" : style
    );
    
    const queryParams = new URLSearchParams({
      city: selectedCity,
      minPrice: priceRange.min,
      maxPrice: priceRange.max,
      bedrooms: selectedBedrooms,
      bathrooms: selectedBathrooms,
      style: mappedPropertySearch.join(','), // Join mapped styles for URL
      search: searchQuery,
      page: 1,
    }).toString();

    // Update URL with new query parameters
    window.history.pushState(null, '', `/listings?${queryParams}`);

    // Reset to first page and refetch listings and clusters
    setCurrentPage(1);
    fetchClusterListings(); // Update pagination based on filtered results
    fetchPageListings(); // Fetch listings based on updated filters
  }}
  className='apply-button'
>
  Apply
</button>



          </div>
        )}
      </div>
    </div>
        <div className='filter-item'>
          <label>Bedrooms:</label>
          <select
            value={selectedBedrooms}
            onChange={(e) => handleFilterChange(setSelectedBedrooms)(e.target.value)}
            className='bedrooms-select'
          >
            <option value='Any'>Any</option>
            {initialBedrooms.map((bedrooms, index) => (
              <option key={index} value={bedrooms}>
                {bedrooms}
              </option>
            ))}
          </select>
        </div>
        
 

        <div className='filter-item'>
          <label>Bathrooms:</label>
          <select
            value={selectedBathrooms}
            onChange={(e) => handleFilterChange(setSelectedBathrooms)(e.target.value)}
            className='bathrooms-select'
          >
            <option value='Any'>Any</option>
            {initialBathrooms.map((bathrooms, index) => (
              <option key={index} value={bathrooms}>
                {bathrooms}
              </option>
            ))}
          </select>
        </div>
        
        
        <div className='filter-item search-filter'>
  <label>Search Address:</label>
  <div ref={dropdownRef} className="search-input-container">
    <input
      type='text'
      value={manualSearchQuery}  // Bind to manualSearchQuery
      onChange={handleCityInputChange}  // Update manualSearchQuery on input change
      onKeyPress={handleSearchKeyPress}  // Search when Enter is pressed
      placeholder='Address, Street or City'
      className='search-input'
    />
    <button onClick={handleSearch} className="search-button">
      <FaSearch />
    </button>

    {/* City and Neighborhood suggestions dropdown */}
    {filteredCities.length > 0 && (
      <ul className="city-suggestions">
  {filteredCities.map((item, index) => {
    const startIndex = item.label.toLowerCase().indexOf(manualSearchQuery.toLowerCase());
    const endIndex = startIndex + manualSearchQuery.length;

    const beforeMatch = item.label.slice(0, startIndex);
    const matchText = item.label.slice(startIndex, endIndex);
    const afterMatch = item.label.slice(endIndex);

    return (
      <li key={index} onClick={() => handleCitySelection(item)}>
        <div className="suggestion-item">
          <span className="city-name">
            {beforeMatch}
            <strong>{matchText}</strong>
            {afterMatch}
          </span>
          <span className="city-label">{item.type}</span>
        </div>
      </li>
    );
  })}
</ul>

    
    )}
  </div>
</div>


      </div>
      {/* Mobile-specific filter section */}
      <div className='mobile-filter-section'>
        <button className='filter-toggle-btn' onClick={toggleMobileFilters}>
          Filter Your Search →
        </button>
        {showMobileFilters && (
          <div className='mobile-filter-options'>
            <div className='filter-item price-range-filter'>
              <label>Price Range:</label>
              <div className="price-inputs">
                <input
                  type="number"
                  placeholder="Min"
                  value={priceRange.min}
                  onChange={(e) => setPriceRange({ ...priceRange, min: e.target.value })}
                  className="price-input"
                />
                <span> - </span>
                <input
                  type="number"
                  placeholder="Max"
                  value={priceRange.max}
                  onChange={(e) => setPriceRange({ ...priceRange, max: e.target.value })}
                  className="price-input"
                />
              </div>
            </div>
            <div className='filter-item'>
              <label>City:</label>
              <select
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
                className='city-select'
              >
                <option value='All'>Select City</option>
                {uniqueCities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
            </div>
            <div className='filter-item'>
              <label>Bedrooms:</label>
              <select
                value={selectedBedrooms}
                onChange={(e) => setSelectedBedrooms(e.target.value)}
                className='bedrooms-select'
              >
                <option value='Any'>Any</option>
                {initialBedrooms.map((bedrooms, index) => (
                  <option key={index} value={bedrooms}>
                    {bedrooms}
                  </option>
                ))}
              </select>
            </div>
            <div className='filter-item'>
              <label>Bathrooms:</label>
              <select
                value={selectedBathrooms}
                onChange={(e) => setSelectedBathrooms(e.target.value)}
                className='bathrooms-select'
              >
                <option value='Any'>Any</option>
                {initialBathrooms.map((bathrooms, index) => (
                  <option key={index} value={bathrooms}>
                    {bathrooms}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
      <div className='applied-filters-bar'>
  {selectedCity !== 'All' && selectedCity !== '' && (
    <button
      onClick={() => removeFilter('city')}
      className='filter-button'
    >
      City: {selectedCity} <span className='remove-filter'>X</span>
    </button>
  )}

{selectedStyle !== 'All' && (
        <button
          onClick={() => removeFilter('style')}
          className='filter-button'
        >
          Style: {selectedStyle} <span className='remove-filter'>X</span>
        </button>
      )}
  {selectedNeighborhood !== '' && selectedNeighborhood !== 'All' && (
    <button
      onClick={() => removeFilter('neighborhood')}
      className='filter-button'
    >
      Neighborhood: {selectedNeighborhood} <span className='remove-filter'>X</span>
    </button>
  )}
  {(priceRange.min !== 0 || priceRange.max !== 10000000) && (
    <button
      onClick={() => removeFilter('price')}
      className='filter-button'
    >
      Price: {formatPrice(priceRange.min)} - {formatPrice(priceRange.max)}{' '}
      <span className='remove-filter'>X</span>
    </button>
  )}
  {/* Property Search Filter */}
  {selectedPropertySearch.length > 0 && selectedPropertySearch !== 'All' && (
    <button onClick={() => removeFilter('propertySearch')} className='filter-button'>
      Property Style: {selectedPropertySearch.join(', ')} <span className='remove-filter'>X</span>
    </button>
  )}
  {selectedBedrooms !== 'Any' && (
    <button
      onClick={() => removeFilter('bedrooms')}
      className='filter-button'
    >
      Bedrooms: {selectedBedrooms} <span className='remove-filter'>X</span>
    </button>
  )}
  {selectedBathrooms !== 'Any' && (
    <button
      onClick={() => removeFilter('bathrooms')}
      className='filter-button'
    >
      Bathrooms: {selectedBathrooms} <span className='remove-filter'>X</span>
    </button>
  )}
  {searchApplied && manualSearchQuery && selectedCity === 'All' && selectedNeighborhood === '' && (
    <button
      onClick={() => removeFilter('search')}
      className='filter-button'
    >
      Search: {manualSearchQuery} <span className='remove-filter'>X</span>
    </button>
  )}
  {/* Clear All Button */}
  {(selectedCity !== 'All' ||
    selectedStyle !== 'All' ||
    selectedPropertySearch.length > 0 ||
    (priceRange.min !== 0 || priceRange.max !== 10000000) ||
    selectedBedrooms !== 'Any' ||
    selectedBathrooms !== 'Any') && (
    <button onClick={clearFilters} className='clear-filters-button'>
      Clear All
    </button>
  )}
</div>

      <div className='main-content'>

        <div className='listings-column'>
          <div className={`listing-grid ${isFirstLoad ? 'transition-container' : ''}`}>
            {loading ? (
              Array.from({ length: listingsPerPage }).map((_, index) => (
                <div key={index} className="listing-card loading-card">
                  {/* The loading animation is applied here */}
                </div>
              ))
            ) : filteredListings.length > 0 ? (
              filteredListings.map((listing) => {
              

                // Only blur listings that do not have search_type as 'IDX'
                const shouldBlur = listing.search_type !== 'IDX' && !user;
              
                return (
                  <div
                    key={listing.ml_num}
                    className={`listing-card ${shouldBlur ? 'blurred' : ''}`} // Apply 'blurred' class if not IDX
                    onClick={(e) => handleImageClick(e, listing)}
                    onMouseEnter={() => setHoveredListing(listing)}
                    onMouseLeave={() => setHoveredListing(null)}
                  >
                    {/* Render the image */}
                    {renderImage(listing)}
              
                    {/* Blur the listing content if necessary */}
                    <div className={`listing-content ${shouldBlur ? 'blurred' : ''}`}>
                      {/* The rest of your listing content goes here */}
                      <div className="listing-style-badge">
                        <span><a href="/contact">Buy and Get $5000 Rebate</a></span>
                      </div>
              
                      <div className="listing-details">
                        <div className="listing-detail-item">
                          <BedroomIcon className="icon" />
                          <span className="with-words"> {listing.bedrooms || 'N/A'}</span>
                          <span className="without-words">{listing.bedrooms || 'N/A'}</span>
                        </div>
                        <div className="listing-detail-item">
                          <BathroomIcon className="icon" />
                          <span className="with-words"> {listing.bathrooms || 'N/A'}</span>
                          <span className="without-words">{listing.bathrooms || 'N/A'}</span>
                        </div>
                        <div className="listing-detail-item">
                          SQFT: {listing.square_feet || 'N/A'}
                        </div>
                      </div>
              
                      <div className="listing-title">
                        <div className="title-community">
                          <h3 className="street-address"> {listing.address} {listing.unit_num ? `#${listing.unit_num}` : ''}</h3>
                          <p className="community">
                            {listing.community}{' '}
                            <span className="separator-dot">•</span>{' '}
                            {listing.municipality}
                          </p>
                        </div>
                        <div className="listing-price">
                          {formatPrice(listing.price)}
                        </div>
                      </div>
                    </div>
              
                    {/* Optional: Show overlay if blurred */}
                    {!user && (listing.search_type === 'NULL' || listing.search_type === 'VOW') && (
                      <div className="login-button-overlay">
                        <a href="/login" className="login-button">
                          Login to View Listing
                        </a>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div>Cannot find any listings matching your criteria, please try again</div>
            )}
          </div>

          <div className='pagination'>
            {currentPage > 1 && (
              <button onClick={() => paginate(currentPage - 1)}>&laquo;</button>
            )}

            {pageNumbers[0] > 1 && (
              <>
                <button onClick={() => paginate(1)}>1</button>
                <span>...</span>
              </>
            )}

            {pageNumbers.map((page) => (
              <button
                key={page}
                onClick={() => paginate(page)}
                className={`pagination-button ${
                  currentPage === page ? 'active' : ''
                }`}
              >
                {page}
              </button>
            ))}

            {pageNumbers[pageNumbers.length - 1] < totalPages && (
              <>
                <span>...</span>
                <button onClick={() => paginate(totalPages)}>
                  {totalPages}
                </button>
              </>
            )}

            {currentPage < totalPages && (
              <button onClick={() => paginate(currentPage + 1)}>&raquo;</button>
            )}
          </div>
        </div>
      </div>

      {selectedListing && (
        <>
          <div className='listing-popup-overlay' onClick={() => setSelectedListing(null)}></div>
          <div className='listing-popup'>
            <div className='banner'>
              <div className='banner-left'>
                <a href="#" className='share-link' onClick={handleShare}>
                  <span>Share</span> <FaShareAlt className='banner-icon' />
                </a>
              </div>
              <div className='banner-middle'>
                {[
                  { name: 'Overview', anchor: '#overview' },
                  { name: 'Sold/Listing History', anchor: '#listing-history' },
                  { name: 'Facts & Features', anchor: '#facts-features' },
                  { name: 'Payment Calculator', anchor: '#payment-calculator' },
                ].map((item, index) => (
                  <a key={index} href={item.anchor} className='banner-menu-item'>
                    {item.name}
                  </a>
                ))}
              </div>
              <div className='banner-right'>
                <button
                  className='popup-close-button'
                  onClick={() => setSelectedListing(null)}
                >
                  X
                </button>
              </div>
            </div>

            <div className='popup-content'>
              <ListingDetail ml_num={selectedListing.ml_num} />
            </div>
          </div>
        </>
      )}
      {showSharePopup && (
        <SharePopup
          onClose={() => setShowSharePopup(false)}
          listingUrl={currentListingUrl}
        />
      )}
    </div>
  );
};

export default Listings;