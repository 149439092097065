import React from "react";
import "./AdvertisingPartners.css";
import advertising1 from '../assets/images/advertising-1.webp';
import advertising2 from '../assets/images/advertising-2.webp';
import advertising3 from '../assets/images/advertising-3.webp';
import advertising4 from '../assets/images/advertising-4.webp';
import advertising5 from '../assets/images/advertising-5.webp';
import advertising6 from '../assets/images/advertising-6.webp';
import advertising7 from '../assets/images/advertising-7.webp';
import advertising8 from '../assets/images/advertising-8.webp';
import advertising9 from '../assets/images/toronto-star.webp';

const AdvertisingPartners = () => {
  const partnerImages = [
    advertising9,
    advertising1,
    advertising2,
    advertising3,
    advertising4,
    advertising5,
    advertising6,
    advertising7,
    advertising8,
  ];

  return (
    <div className="advertising-partners-container">
      <div className="advertising-partners-ticker-wrapper">
        <div className="advertising-partners-ticker">
          {/* Render the images twice for an infinite wrap-around effect */}
          {partnerImages.concat(partnerImages).map((image, index) => (
            <img key={index} src={image} alt={`Partner ${index + 1}`} className="advertising-partners-image" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdvertisingPartners;
