import React, { useState, useRef } from 'react';
import axios from '../services/axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { recaptchaSiteKey } from '../services/constants';
import '../Auth.css'; // Import the Auth.css for styling

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
  const [message, setMessage] = useState(null);
  const recaptchaRef = useRef(null);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    setIsRecaptchaVerified(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      alert('Please complete the reCAPTCHA');
      return;
    }

    setIsLoading(true);

    try {
      const res = await axios.post('/api/users/forgot-password', {
        email,
        recaptchaToken,
      });

      if (res.status === 200) {
        setMessage('A password reset link has been sent to your email.');
        setEmail('');
      } else {
        setMessage('Failed to send password reset link. Please try again.');
      }
    } catch (err) {
      console.error(err);
      setMessage('Error sending password reset email.');
    } finally {
      setRecaptchaToken('');
      setIsRecaptchaVerified(false);
      recaptchaRef.current.reset();
      setIsLoading(false);
    }
  };

  return (
    <div className="forgot-password-form">
      <form onSubmit={handleSubmit}>
        <h2>Forgot Password</h2>
        {message && <p className="message">{message}</p>}
        <div>
          <input
            type="email"
            value={email}
            onChange={handleChange}
            placeholder="Enter your email"
            required
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <ReCAPTCHA
            sitekey={recaptchaSiteKey}
            onChange={handleRecaptchaChange}
            ref={recaptchaRef}
          />
        </div>
        <button
          className="list-button"
          type="submit"
          disabled={isLoading || !isRecaptchaVerified}
        >
          {isLoading ? 'Processing...' : 'Send Reset Link'}
        </button>
      </form>
    </div>
  );
};

export default ForgotPassword;
