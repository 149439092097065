import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from '../services/axios';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      console.log('Decoded token:', decodedToken);
      setUser({ id: decodedToken.id, role: decodedToken.role });
    }
  }, []);

  const login = async (email, password, role) => {
    try {
      console.log('Logging in with', { email, password, role });
      const response = await axios.post('/users/login', { email, password, role });
      const { token, user } = response.data;
      console.log('Login response:', response.data);
      localStorage.setItem('token', token);
      setUser(user);
      navigate('/listings'); // Redirect after login
    } catch (error) {
      console.error('Error logging in:', error);
      throw new Error(error.response?.data?.message || 'Please check your email and password.');
    }
  };

  const activateAccount = async (token) => {
    console.log('Activate account');
    try {
      const response = await axios.post(`/users/activate/${token}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Invalid token.");
    }
  }

  const register = async (username, email, password, role) => {
    try {
      console.log('Registering with', { username, email, password, role });
      return await axios.post('/users/register', { username, email, password, role });
      // Automatically log the user in after successful registration
    } catch (error) {
      console.error('Error registering:', error);
      throw new Error(error.response?.data?.message || 'Registration failed.');
    }
  };

  const forgotPassword = async (email) => {
    try {
      console.log('Requesting password reset for', email);
      await axios.post('/users/forgot-password', { email });
    } catch (error) {
      console.error('Error sending password reset email:', error);
      throw new Error(error.response?.data?.message || 'Failed to send password reset email.');
    }
  };

  const logout = () => {
    console.log('Logging out');
    localStorage.removeItem('token');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, register, forgotPassword, logout, activateAccount }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContext;
