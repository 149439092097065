import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import NavbarHome from './components/NavbarHome';
import NavbarLandingPage from './components/NavbarLandingPage';
import NavbarListings from './components/NavbarListings'; 
import Home from './components/Home';
import Buy from './components/Buy';
import Sell from './components/Sell';
import Search from './components/Search';
import { useParams } from 'react-router-dom';

import Articles from './components/Articles';
import ArticleDetail from './components/ArticleDetail';
import Contact from './components/Contact';
import CommissionExplained from './components/CommissionExplained';
import GeolocationComponent from './components/GeolocationComponent';
import ThankYou from './components/ThankYou';
import SellingPage from './components/SellingPage';
import Listings from './components/Listings';
import ListingDetail from './components/ListingDetail';
import Wishlist from './components/Wishlist';
import Login from './components/Login';
import Register from './components/Register';
import Users from './components/Users';
import ForgotPassword from './components/ForgotPassword';
import AddArticle from './components/AddArticle';
import ActivateAccount from "./components/ActivateAccount";
import useScroll from './useScroll';
import { AuthProvider } from './context/AuthContext';
import './App.css';
import CityPages from './components/CityPages';


// List of valid cities
const validCities = [
  'Adjala-Tosorontio', 'Ajax', 'Aurora', 'Barrie', 'Brampton', 'Brock', 'Burlington',
  'Caledon', 'Cambridge', 'Centre Wellington', 'Chatsworth', 'Clarington', 'Collingwood',
  'East Gwillimbury', 'East Luther Grand Valley', 'Erin', 'Essa', 'Fort Erie',
  'Galway-Cavendish and Harvey', 'Gananoque', 'Georgian Bay', 'Georgian Bluffs',
  'Georgina', 'Grimsby', 'Guelph', 'Guelph/Eramosa', 'Haldimand', 'Halton Hills',
  'Hamilton', 'Innisfil', 'Kawartha Lakes', 'King', 'Kingston', 'Kitchener',
  'Lincoln', 'London', 'Markham', 'Midland', 'Milton', 'Mississauga', 'New Tecumseth',
  'Newmarket', 'Niagara Falls', 'Niagara-on-the-Lake', 'Oakville', 'Orangeville',
  'Orillia', 'Oro-Medonte', 'Oshawa', 'Ottawa', 'Owen Sound', 'Penetanguishene',
  'Peterborough', 'Pickering', 'Port Colborne', 'Scugog', 'Severn', 'Shelburne',
  'Tiny', 'Toronto', 'Vaughan', 'Wainfleet', 'Wasaga Beach', 'Waterloo', 'Welland',
  'Whitby', 'Whitchurch-Stouffville', 'Woolwich'
];

const CityPageRoute = () => {
  const { city } = useParams();
  const navigate = useNavigate();
  const cityCapitalized = city
    .split('-')
    .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join(' ');

  if (!validCities.includes(cityCapitalized)) {
    navigate('/'); // Redirect if invalid city
    return null;
  }

  return <Listings city={cityCapitalized} />;
};

function AppContent() {
  useScroll();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [detectedCity, setDetectedCity] = useState(null);

  const handleCityFound = (city) => {
    setDetectedCity(city);
  };

  useEffect(() => {
    // Get the current city from the query parameters
    const currentCity = searchParams.get('city');
  
    // If there's no city in the query parameters and the path is `/listings`
    if (!currentCity && location.pathname === '/listings' && detectedCity) {
      setSearchParams({ city: detectedCity });
  
      // Check if the page has already been refreshed
      if (!localStorage.getItem('pageRefreshed')) {
        // Set the flag to indicate the page has been refreshed
        localStorage.setItem('pageRefreshed', 'true');
        
        // Perform a browser refresh after setting the city
        window.location.reload();
      }
    }
  }, [detectedCity, location.pathname, searchParams, setSearchParams]);
  
  // Optional: Clear the flag when navigating away from the listings page or on page load
  useEffect(() => {
    if (location.pathname !== '/listings') {
      localStorage.removeItem('pageRefreshed');
    }
  }, [location.pathname]);
  
  return (
    <div className="App">
      {location.pathname === '/sell-your-home-for-1-percent' ? (
        <NavbarLandingPage />
      ) : location.pathname === '/listings' ? (
        <NavbarListings detectedCity={detectedCity} />
      ) : (
        <NavbarHome detectedCity={detectedCity} />
      )}
      <GeolocationComponent onCityFound={handleCityFound} />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/buy' element={<Buy />} />
        <Route path='/sell' element={<Sell />} />
        <Route path='/search' element={<Search />} />
        <Route path='/articles' element={<Articles />} />
        <Route path='/articles/:id' element={<ArticleDetail />} />
        <Route path='/contact' element={<Contact />} />

        <Route path='/commission-explained' element={<CommissionExplained />} />
        <Route path='/city/:city' element={<CityPages />} />
        <Route path='/thank-you' element={<ThankYou />} />
        <Route path='/listings' element={<Listings city={searchParams.get('city') || null} />} />
        <Route path='/listing/:ml_num/:address' element={<ListingDetail />} />
        <Route path='/wishlist' element={<Wishlist />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/register/activate/:token' element={<ActivateAccount />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/users' element={<Users />} />
        <Route path='/add-article' element={<AddArticle />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

export default App;
