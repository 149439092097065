import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './ImageSlider.css';

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    />
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    />
  );
};

const ImageSlider = ({ images, onImageClick, className, lazyLoad = false }) => {
  const [loadedImages, setLoadedImages] = useState([]);

  useEffect(() => {
    if (!lazyLoad) {
      setLoadedImages(images);
    } else {
      // Load only the first image initially
      setLoadedImages([images[0]]);
    }
  }, [images, lazyLoad]);

  const handleLazyLoad = (index) => {
    if (lazyLoad && !loadedImages[index]) {
      setLoadedImages((prevLoaded) => {
        const newLoaded = [...prevLoaded];
        newLoaded[index] = images[index];
        return newLoaded;
      });
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    appendDots: dots => (
      <div onClick={(e) => e.stopPropagation()}>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div className="custom-dot" onClick={(e) => e.stopPropagation()}></div>
    ),
    beforeChange: (current, next) => handleLazyLoad(next), // Lazy load the next image
  };

  return (
    <Slider {...settings} className={`listing-slider ${className}`}>
      {images.map((image, index) => (
        <div key={index} onClick={onImageClick}>
          <img src={loadedImages[index] || images[0]} alt={`Slide ${index}`} />
        </div>
      ))}
    </Slider>
  );
};

export default ImageSlider;
