import React from 'react';
import { Helmet } from 'react-helmet';

const Search = () => {
  return (
    <div>
      <Helmet>
        <title>Modern Solution Realty - Search</title>
      </Helmet>
      <h1>Search Page</h1>
      <p>This is the Search page.</p>
    </div>
  );
};

export default Search;
