import React, { useState, useEffect, useContext } from 'react';
import axios from '../services/axios';
import './Listings.css';
import AuthContext from '../context/AuthContext';
import { ReactComponent as BedroomIcon } from '../assets/icons/bedroom-3.svg';
import { ReactComponent as BathroomIcon } from '../assets/icons/bathroom.svg';
import ListingDetail from './ListingDetail';
import { FaShareAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import './CityPages.css';

const CityPages = () => {
  const { city } = useParams(); // Get city from the URL
  const [currentPage, setCurrentPage] = useState(1);
  const [priceRange, setPriceRange] = useState({ min: 0, max: 10000000 });
  const [shareText, setShareText] = useState('Share');
  const [selectedBedrooms, setSelectedBedrooms] = useState('Any');
  const [selectedBathrooms, setSelectedBathrooms] = useState('Any');
  const [searchQuery, setSearchQuery] = useState('');
  const [hoveredListing, setHoveredListing] = useState(null);
  const [selectedListing, setSelectedListing] = useState(null);
  const [listings, setListings] = useState([]);
  const [filteredListings, setFilteredListings] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [totalListings, setTotalListings] = useState(0);
  const listingsPerPage = 16;

  const { user } = useContext(AuthContext);

  useEffect(() => {
    // Fetch the total number of listings for the selected city
    const fetchTotalListings = async () => {
      try {
        const response = await axios.get('/listings/count', {
          params: {
            city,
            bedrooms: selectedBedrooms !== 'Any' ? selectedBedrooms : null,
            bathrooms: selectedBathrooms !== 'Any' ? selectedBathrooms : null,
            search: searchQuery || null,
            minPrice: priceRange.min,
            maxPrice: priceRange.max,
          },
        });
        setTotalListings(response.data.totalCount); // Set the total listings count
      } catch (error) {
        console.error('Error fetching total listings:', error);
      }
    };

    fetchTotalListings();
  }, [city, selectedBedrooms, selectedBathrooms, searchQuery, priceRange]);

  useEffect(() => {
    // Fetch listings for the current page
    const fetchPageListings = async () => {
      setLoading(true);
      try {
        const pageOffset = (currentPage - 1) * listingsPerPage;
        const response = await axios.get('/listings', {
          params: {
            limit: listingsPerPage,
            offset: pageOffset,
            city,
            bedrooms: selectedBedrooms !== 'Any' ? selectedBedrooms : null,
            bathrooms: selectedBathrooms !== 'Any' ? selectedBathrooms : null,
            search: searchQuery || null,
            minPrice: priceRange.min,
            maxPrice: priceRange.max,
          },
        });

        setFilteredListings(response.data);
        setListings(response.data);
        setLoading(false);
      } catch (error) {
        setError('Error fetching listings');
        setLoading(false);
      }
    };

    fetchPageListings();
  }, [currentPage, selectedBedrooms, selectedBathrooms, searchQuery, priceRange, city]);

  const formatPrice = (price) => {
    return `$${Number(price).toLocaleString()}`;
  };

  const handleFilterChange = (setter) => (value) => {
    setter(value);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(totalListings / listingsPerPage);
  const maxVisiblePages = 10;

  // Generate page numbers to display in pagination
  const getPageNumbers = () => {
    const pages = [];
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    // Adjust the start and end if needed
    if (endPage - startPage + 1 < maxVisiblePages) {
      const additionalPages = maxVisiblePages - (endPage - startPage + 1);
      startPage = Math.max(1, startPage - additionalPages);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  const pageNumbers = getPageNumbers();

  const clearFilters = () => {
    setPriceRange({ min: 0, max: 10000000 });
    setSelectedBedrooms('Any');
    setSelectedBathrooms('Any');
    setSearchQuery('');
    setCurrentPage(1);
  };

  const handleImageClick = (e, listing) => {
    e.stopPropagation();
    const shouldBlur = listing.search_type !== 'IDX' && !user;

    if (shouldBlur) {
      return;
    }

    setSelectedListing(listing);
  };

  const renderImage = (listing) => {
    const mainImage =
      listing.main_image ||
      (Array.isArray(listing.photo_urls) ? listing.photo_urls[0] : listing.photo_urls.split(',')[0]);

    const shouldBlur = listing.search_type !== 'IDX' && !user;

    return (
      <div className='image-container'>
        <img
          src={mainImage}
          alt='Listing'
          className={`listing-image ${shouldBlur ? 'blurred' : ''}`}
          onClick={(e) => handleImageClick(e, listing)}
        />
        {shouldBlur && (
          <div className='login-button-overlay'>
            <a href='/login' className='login-button'>
              Login to view details
            </a>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='city-page-container'>
      <div className='listing-search-container'>
        {error && <div className='error-message'>Error: {error}</div>}

        <div className='filter-bar'>
          <div className='filter-item price-range-filter'>
            <label>Price Range:</label>
            <div className='price-inputs'>
              <input
                type='number'
                placeholder='Min'
                value={priceRange.min}
                onChange={(e) =>
                  handleFilterChange(setPriceRange)({ ...priceRange, min: e.target.value })
                }
                className='price-input'
              />
              <span> - </span>
              <input
                type='number'
                placeholder='Max'
                value={priceRange.max}
                onChange={(e) =>
                  handleFilterChange(setPriceRange)({ ...priceRange, max: e.target.value })
                }
                className='price-input'
              />
            </div>
          </div>
          <div className='filter-item'>
            <label>Bedrooms:</label>
            <select
              value={selectedBedrooms}
              onChange={(e) => handleFilterChange(setSelectedBedrooms)(e.target.value)}
              className='bedrooms-select'
            >
              <option value='Any'>Any</option>
              {[...Array(9).keys()].map((bedrooms) => (
                <option key={bedrooms} value={bedrooms + 1}>
                  {bedrooms + 1}
                </option>
              ))}
            </select>
          </div>
          <div className='filter-item'>
            <label>Bathrooms:</label>
            <select
              value={selectedBathrooms}
              onChange={(e) => handleFilterChange(setSelectedBathrooms)(e.target.value)}
              className='bathrooms-select'
            >
              <option value='Any'>Any</option>
              {[...Array(10).keys()].map((bathrooms) => (
                <option key={bathrooms} value={bathrooms + 1}>
                  {bathrooms + 1}
                </option>
              ))}
            </select>
          </div>
          <div className='filter-item search-filter'>
            <label>Search Address:</label>
            <input
              type='text'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder='Search listings'
              className='search-input'
            />
          </div>
          <button onClick={clearFilters} className='clear-filters-button'>
            Clear Filters
          </button>
        </div>

        <div className='listings-column'>
          <div className={`listing-grid ${isFirstLoad ? 'transition-container' : ''}`}>
            {loading ? (
              Array.from({ length: listingsPerPage }).map((_, index) => (
                <div key={index} className='listing-card loading-card'></div>
              ))
            ) : filteredListings.length > 0 ? (
              filteredListings.map((listing) => (
                <div
                  key={listing.ml_num}
                  className={`listing-card ${listing.search_type !== 'IDX' && !user ? 'blurred' : ''}`}
                  onClick={(e) => handleImageClick(e, listing)}
                  onMouseEnter={() => setHoveredListing(listing)}
                  onMouseLeave={() => setHoveredListing(null)}
                >
                  {renderImage(listing)}
                  <div className={`listing-content ${listing.search_type !== 'IDX' && !user ? 'blurred' : ''}`}>
                    <div className='listing-style-badge'>
                      <span>
                        <a href='/contact'>Buy and Get $5000 Cash Back</a>
                      </span>
                    </div>
                    <div className='listing-details'>
                      <div className='listing-detail-item'>
                        <BedroomIcon className='icon' />
                        <span>{listing.bedrooms || 'N/A'}</span>
                      </div>
                      <div className='listing-detail-item'>
                        <BathroomIcon className='icon' />
                        <span>{listing.bathrooms || 'N/A'}</span>
                      </div>
                      <div className='listing-detail-item'>
                        SQFT: {listing.square_feet || 'N/A'}
                      </div>
                    </div>
                    <div className='listing-title'>
                      <h3 className='street-address'>
                        {listing.address} {listing.unit_num ? `#${listing.unit_num}` : ''}
                      </h3>
                      <p className='community'>
                        {listing.community} <span className='separator-dot'>•</span> {listing.municipality}
                      </p>
                      <div className='listing-price'>{formatPrice(listing.price)}</div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>No listings found for your criteria.</div>
            )}
          </div>

          <div className='pagination'>
            {/* Previous Button */}
            {currentPage > 1 && <button onClick={() => paginate(currentPage - 1)}>&laquo;</button>}

            {/* First page button */}
            {pageNumbers[0] > 1 && (
              <>
                <button onClick={() => paginate(1)}>1</button>
                <span>...</span>
              </>
            )}

            {/* Main visible pagination buttons */}
            {pageNumbers.map((page) => (
              <button
                key={page}
                onClick={() => paginate(page)}
                className={`pagination-button ${currentPage === page ? 'active' : ''}`}
              >
                {page}
              </button>
            ))}

            {/* Last page button */}
            {pageNumbers[pageNumbers.length - 1] < totalPages && (
              <>
                <span>...</span>
                <button onClick={() => paginate(totalPages)}>{totalPages}</button>
              </>
            )}

            {/* Next Button */}
            {currentPage < totalPages && <button onClick={() => paginate(currentPage + 1)}>&raquo;</button>}
          </div>
        </div>

        {selectedListing && (
          <>
            <div className='listing-popup-overlay' onClick={() => setSelectedListing(null)}></div>
            <div className='listing-popup'>
              <div className='banner'>
                <div className='banner-left'>
                  <a
                    href='#'
                    className='share-link'
                    onClick={(e) => {
                      e.preventDefault();
                      const addressSlug = selectedListing.address
                        .replace(/ /g, '-')
                        .replace(/[^a-zA-Z0-9\-]/g, '');
                      const unitNum = selectedListing.unit_num ? `-${selectedListing.unit_num}` : '';
                      const link = `${window.location.origin}/listing/${selectedListing.ml_num}/${addressSlug}${unitNum}`;
                      navigator.clipboard.writeText(link).then(() => {
                        setShareText('Copied Listing Link to Clipboard');
                        setTimeout(() => {
                          setShareText('Share');
                        }, 3000);
                      });
                    }}
                  >
                    <span>{shareText}</span> <FaShareAlt className='banner-icon' />
                  </a>
                </div>
                <div className='banner-middle'>
                  {['Overview', 'Sold/Listing History', 'Facts & Features', 'Payment Calculator'].map(
                    (item, index) => (
                      <a key={index} href={`#${item.toLowerCase().replace(/ /g, '-')}`} className='banner-menu-item'>
                        {item}
                      </a>
                    )
                  )}
                </div>
                <div className='banner-right'>
                  <button className='popup-close-button' onClick={() => setSelectedListing(null)}>
                    X
                  </button>
                </div>
              </div>

              <div className='popup-content'>
                <ListingDetail ml_num={selectedListing.ml_num} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CityPages;
