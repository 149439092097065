import { useEffect } from 'react';

const useScroll = () => {
  useEffect(() => {
    const handleScroll = () => {
      const topBar = document.querySelector('.top-bar');
      const navbar = document.querySelector('.navbar');
      if (window.scrollY > 50) {
        topBar.classList.add('sticky');
        navbar.classList.add('sticky');
      } else {
        topBar.classList.remove('sticky');
        navbar.classList.remove('sticky');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
};

export default useScroll;
