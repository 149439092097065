import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import './ArticleDetail.css';

const ArticleDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const [error, setError] = useState(null);
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const articleFiles = ['article1.json', 'article2.json', 'article3.json', 'article4.json', 'article5.json', 'article6.json', 'article7.json', 'article8.json', 'article9.json', 'article10.json'];
        const fetchedArticles = await Promise.all(
          articleFiles.map((file) =>
            fetch(`${process.env.PUBLIC_URL}/articles/${file}`).then((res) =>
              res.json()
            )
          )
        );

        const articlesWithSlugs = fetchedArticles.map(article => ({
          ...article,
          slug: createSlug(article.title)
        }));

        const sortedArticles = articlesWithSlugs.sort((a, b) => new Date(b.date) - new Date(a.date));
        setArticles(sortedArticles);
        setFilteredArticles(sortedArticles); // Initially show all articles

        const uniqueCategories = [...new Set(fetchedArticles.map(article => article.category))];
        setCategories(uniqueCategories);
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };

    fetchArticles();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const results = articles.filter(article =>
        article.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredArticles(results);
    } else if (selectedCategory) {
      const results = articles.filter(article =>
        article.category === selectedCategory
      );
      setFilteredArticles(results);
    } else {
      setFilteredArticles(articles);
    }
  }, [searchTerm, selectedCategory, articles]);

  useEffect(() => {
    const fetchArticle = async () => {
      if (!id) return;
      try {
        const articleFiles = ['article1.json', 'article2.json', 'article3.json', 'article4.json', 'article5.json', 'article6.json', 'article7.json', 'article8.json', 'article9.json', 'article10.json'];
        let foundArticle = null;

        for (const file of articleFiles) {
          const response = await fetch(`${process.env.PUBLIC_URL}/articles/${file}`);
          if (response.ok) {
            const data = await response.json();
            if (data.slug === id) {
              foundArticle = data;
              break;
            }
          } else {
            console.error(`Error fetching ${file}: ${response.statusText}`);
          }
        }

        if (foundArticle) {
          setArticle(foundArticle);
          setError(null);
        } else {
          throw new Error('Article not found');
        }
      } catch (error) {
        console.error('Error fetching the article:', error);
        setError('Article not found. Please check the URL or try again later.');
        setArticle(null);
      }
    };

    fetchArticle();
  }, [id]);

  const createSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSearchTerm('');
    setArticle(null); // Clear any currently displayed article
    navigate('/articles'); // Update URL to reflect articles page view
  };

  const handleViewAll = () => {
    setSelectedCategory('');
    setSearchTerm('');
    setArticle(null); // Clear any currently displayed article
    setFilteredArticles(articles); // Ensure all articles are shown
    navigate('/articles'); // Update URL to reflect articles page view
  };

  return (
    <div className="articles-container">
      <div className="sidebar">
        <div className="recent-articles">
          <h3>Recent Articles</h3>
          <hr className="sidebar-divider" />
          {articles.slice(0, 3).map((article, index) => (
            <Link
              key={index}
              to={`/articles/${article.slug}`}
              className="sidebar-item"
            >
              <div>{article.title}</div>
              <div className="article-date">{article.date}</div>
            </Link>
          ))}
        </div>
        <button
          className="view-all-button"
          onClick={handleViewAll}
        >
          View All Articles
        </button>
        <div className="categories">
          <h3>Categories</h3>
          <hr className="sidebar-divider" />
          {categories.length > 0 ? (
            categories.map((category, index) => (
              <div
                key={index}
                className={`category-item ${selectedCategory === category ? 'selected' : ''}`}
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </div>
            ))
          ) : (
            <p>No categories found.</p>
          )}
        </div>
      </div>
      <div className="content">
        {searchTerm && filteredArticles.length === 0 ? (
          <p>No results found for "{searchTerm}".</p>
        ) : selectedCategory || !article ? (
          filteredArticles.map((article, index) => (
            <div key={index} className="article-preview">
              <Link to={`/articles/${article.slug}`}>
                <h2>{article.title}</h2>
              </Link>
              <div className="article-category">{article.category}</div>
              <div className="article-date">{article.date}</div>
              <p dangerouslySetInnerHTML={{ __html: article.content.slice(0, 150) + '...' }}></p>
              <Link to={`/articles/${article.slug}`} className="read-more-button">Read More</Link>
            </div>
          ))
        ) : (
          error ? (
            <div className="error-message">{error}</div>
          ) : (
            article && (
              <>
                <h2>{article.title}</h2>
                <div className="article-category">{article.category}</div>
                <div className="article-date">{article.date}</div>
                <div
                  className="article-content"
                  dangerouslySetInnerHTML={{ __html: article.content }}
                />
              </>
            )
          )
        )}
      </div>
    </div>
  );
};

export default ArticleDetail;
