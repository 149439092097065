import React from 'react';
import './CallToAction-BottomRight.css';

const CallToActionBottomRight = () => {
  return (
    <div id="contact-bar">
      <a href="tel:9058975000" className="contact-button buying-button">
      Call Us
      </a>
      <a href="/contact" className="contact-button selling-button">
        Email Us
      </a>
    </div>
  );
};

export default CallToActionBottomRight;
