import React from 'react';
import { PiPhoneListThin, PiEnvelopeOpenThin } from 'react-icons/pi';
import CallToActionBottomRight from './CallToAction-BottomRight';
import AdvertisingPartners from './AdvertisingPartners';
import SEOCities from './SEOCities'; // Import the SEO Cities component
import './Footer.css';

const Footer = () => {
  return (
    <>
    <div className="footer-top-container">
      <SEOCities /> {/* Add the SEO Cities component here */}
      </div>
      <div className="advertising-container">
      <h5>Our Advertising Partners</h5>
      <AdvertisingPartners />
      </div>
      <footer className="footer">
        <div className="footer-container">
         
          <p>&copy; 2024 Modern Solution Realty. All rights reserved.</p>
          <ul className="footer-links">
            <li>
              <PiPhoneListThin className="icon" />
              <a href="tel:+19058975000" className="contact-link">(905) 897-5000</a>
            </li>
            <li>
              <PiEnvelopeOpenThin className="icon" />
              <a href="/contact" className="contact-link">info@modernsolution.ca</a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default Footer;
