import React, {useState, useEffect, useContext} from 'react';
import { Link, useParams } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import '../Auth.css';

const ActivateAccount = () => {
  const params = useParams();
  const { activateAccount } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [accountActive, setAccountActivate] = useState(false);

  useEffect(() => {
    activateAccount(params.token).then((r) => {
      console.log(r);
      setAccountActivate(true);
    });
    document.body.classList.add('Login-page-body');
    return () => {
      document.body.classList.remove('Login-page-body');
    };
  }, [params.token]);

  return (
    <div className="Login-page">
      <div className="Login-container">
        {accountActive ? (
        <div>
          <h1>Thank you</h1>
          <p className="success">Your account is activated.</p>
          <p>
            Please <Link to="/login">Log in</Link>.
          </p>
        </div>
          ) : (
              <div>
                <h1>Thank you</h1>
                {error ? (<p className="error">{error}</p>) : (
                  <>
                    <p>Your account is being activated.</p>
                    <p>Please wait...</p>
                  </>
                )}
              </div>
        )}
      </div>
    </div>
  );
};

export default ActivateAccount;
