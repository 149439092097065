// src/data/cities.js 
export const initialCities = [
'Adjala-Tosorontio', 'Ajax', 'Aurora', 'Barrie', 'Brampton', 'Brock', 'Burlington',
'Caledon', 'Cambridge', 'Centre Wellington', 'Chatsworth', 'Clarington', 'Collingwood',
'East Gwillimbury', 'East Luther Grand Valley', 'Erin', 'Essa', 'Fort Erie',
'Galway-Cavendish and Harvey', 'Gananoque', 'Georgian Bay', 'Georgian Bluffs',
'Georgina', 'Grimsby', 'Guelph', 'Guelph/Eramosa', 'Haldimand', 'Halton Hills',
'Hamilton', 'Innisfil', 'Kawartha Lakes', 'King', 'Kingston', 'Kitchener',
'Lincoln', 'London', 'Markham', 'Midland', 'Milton', 'Mississauga', 'New Tecumseth',
'Newmarket', 'Niagara Falls', 'Niagara-on-the-Lake', 'Oakville', 'Orangeville',
'Orillia', 'Oro-Medonte', 'Oshawa', 'Ottawa', 'Owen Sound', 'Penetanguishene',
'Peterborough', 'Pickering', 'Port Colborne', 'Scugog', 'Severn', 'Shelburne',
'Tiny', 'Toronto', 'Vaughan', 'Wainfleet', 'Wasaga Beach', 'Waterloo', 'Welland',
'Whitby', 'Whitchurch-Stouffville', 'Woolwich'
];