import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import './Testimonials.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  let sliderRef = null;

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await fetch('./Testimonials.json'); // Adjust the path to your JSON file
        const data = await response.json();
        setTestimonials(data);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
      }
    };

    fetchTestimonials();
  }, []);

  

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="unique-testimonials-container">

      <Slider {...sliderSettings} ref={(slider) => (sliderRef = slider)}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="unique-testimonial-slide">
            <div className="unique-testimonial">
              <img src={testimonial.image} alt={testimonial.name} className="unique-testimonial-image" />
              <h3>{testimonial.name}</h3>
              <p>{testimonial.testimonial}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
